import React from "react";
import { Text, TextProps } from "@onnit-js/ui/components/text/Text";

const Title: React.FC<React.PropsWithChildren<React.PropsWithChildren<TextProps>>> = ({ children, ...rest }) => (
    <Text typeStyle="title01" fontSize={[6, 5, 8, 9, 11]} maxWidth={[400, 650]} {...rest}>
        {children}
    </Text>
);

export default Title;
