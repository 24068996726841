import React from "react";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import { Text, H2 } from "@onnit-js/ui/components/text";
import { format, parseISO } from "date-fns";
import { ClickBankOrder } from "../../domain/@types";
import Order from "./Order";
import LoadingSkeletonBox from "../common/LoadingSkeletonBox";
import PageThemeConfig from "../../domain/PageThemeConfig";
import ShippingInformation from "./ShippingInformation";

interface Props extends BoxProps {
    orders?: ClickBankOrder[];
}

const OrderConfirmation: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (
    {
        orders,
        ...boxProps
    }) => {
    // There will be multiple orders if 1+ upsells were added.
    // We use the first order for customer details and address since they will be the same.
    const firstOrder = orders ? orders[0] : undefined;
    return (
        (
            <Box bg={PageThemeConfig.creamWhite} {...boxProps}>
                <Box bg={PageThemeConfig.tanish} p={4} borderBottom={`2px solid ${PageThemeConfig.darkTanish}`}>
                    <Text as="h1" typeStyle="title02" mt={4} mb={2}>Order Confirmation</Text>
                    {firstOrder ? (
                        <Text
                            as="p"
                            typeStyle="text03"
                            fontWeight="regular"
                        >Purchased {format(parseISO(firstOrder.created_at), "MMM d, yyyy")}
                        </Text>
                    ) : (
                        <LoadingSkeletonBox width="13em" />
                    )}
                </Box>
                <H2 className="sr-only">Orders</H2>
                {orders?.map((order) => (
                    <Order
                        key={order.receipt_id}
                        cbOrder={order}
                        borderBottom={`2px solid ${PageThemeConfig.darkTanish}`}
                    />
                )) ?? (
                    // Skeleton loader.
                    (<Order borderBottom={`2px solid ${PageThemeConfig.darkTanish}`} />)
                )}
                <ShippingInformation p={4} mt={4} cbOrder={firstOrder} />
            </Box>
        )
    );
};

export default OrderConfirmation;
