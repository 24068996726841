import React, { useEffect, useState } from "react";
import Box from "@onnit-js/ui/components/box/Box";
import { H2, ListItem, Sup, Text } from "@onnit-js/ui/components/text";
import MessageBox, { MessageLevelEnum } from "@onnit-js/ui/components/notification/MessageBox";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";
import styled from "styled-components";
import clickBankService from "../../domain/ClickBankService";
import { PageProps } from "../../PageRouter";
import { ClickBankOrderListResponse } from "../../domain/@types";
import OrderConfirmation from "./OrderConfirmation";
import NewsletterSignup from "./NewsletterSignup";
import OrderManagementLink from "../common/OrderManagementLink";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";
import PageThemeConfig from "../../domain/PageThemeConfig";
import FdaStatement from "../common/FdaStatement";
import Highlight from "../common/Highlight";
import RefundBox from "./RefundBox";

const StyledList = styled.ul`
  ul, li {
    list-style: none;
    margin: 0;
    padding-left: 0;
  }
`;

const OrderConfirmationPage: React.FC<React.PropsWithChildren<React.PropsWithChildren<PageProps>>> = ({ setIsLoading }) => {
    const [ordersResponse, setOrdersResponse] = useState<ClickBankOrderListResponse>();
    const [error, setError] = useState<boolean>(false);
    const orders = ordersResponse?.orders;
    const firstOrder = orders ? orders[0] : null;
    const [params, iv] = clickBankService.urlManager.getEncryptedUrlParams();

    useDocumentTitle("Your Order Confirmation");
    useEffect(() => {
        setIsLoading(true);
        clickBankService.listOrders(params, iv)
            .then((response) => setOrdersResponse(response))
            .catch(() => setError(true))
            .finally(() => setIsLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Box maxWidth="1920px" mx="auto">
                <Box>
                    <Text
                        textAlign="center"
                        as="p"
                        typeStyle="text02"
                        fontWeight="regular"
                        color={PageThemeConfig.darkGrayish}
                        mt={[4, 4, 6, 8]}
                    >Your order is complete!
                    </Text>
                    <Text
                        typeStyle="title01"
                        fontSize={[4, 4, 6, 8]}
                        textAlign="center"
                        mt={4}
                        px={4}
                        fontStyle="italic"
                    >Thank you for your purchase!
                    </Text>
                    <Box mt={[4, 4, 6, 8]}>
                        <TwelveColumn>
                            <Box gridColumn={["1 / -1", "1 / -1", "2 / span 10", "2 / span 10"]}>
                                {error ? (
                                    <MessageBox
                                        level={MessageLevelEnum.ERROR}
                                        message="We had trouble retrieving your order details. Please check your email for a confirmation or contact us for help."
                                    />
                                ) : (
                                    <OrderConfirmation mx={[4, 4, 0]} orders={orders} />
                                )}
                            </Box>
                        </TwelveColumn>
                    </Box>
                    <TwelveColumn mt={[4, 4, 4, 6]} px={4}>
                        <Box gridColumn={["1 / -1", "1 / -1", "8 / span 4", "8 / span 4"]}>
                            <NewsletterSignup orderEmail={firstOrder?.email ?? ""} gridRow={1} />
                            <RefundBox firstOrder={firstOrder} display={["none", "none", "block"]} />
                        </Box>

                        <Box gridColumn={["1 / -1", "1 / -1", "2 / span 5", "2 / span 5"]}>
                            <H2 className="sr-only">Order Instructions</H2>
                            <Text as="p" fontStyle="text02" fontWeight="regular" py={3}>
                                Now that you've officially secured your
                                bottle{orders && orders.length > 1 ? "s" : ""} of Alpha BRAIN<Sup variant="reg" />,
                                you're just a few short steps away from one of the best nootropics
                                that supports focus, memory, and concentration.<Sup variant="dagger" />
                            </Text>

                            <StyledList>
                                <ListItem>
                                    <Text as="p" fontStyle="text02" fontWeight="regular" py={3}>
                                        <Highlight typeStyle="text03" fontWeight="black">1. Go check your email to
                                            confirm your order.
                                        </Highlight>{" "}
                                        You should've received a receipt from ClickBank Customer Service
                                        (noreply@clickbank.com).
                                        Your bank statement will show a charge
                                        from {ordersResponse?.billing_descriptor ?? "CLKBANK"}.
                                        Check your order details for accuracy and if there's anything wrong,
                                        let's get that handled BEFORE we ship your Alpha BRAIN<Sup
                                            variant="reg"
                                        /> by{" "}
                                        <OrderManagementLink
                                            receiptId={firstOrder?.receipt_id}
                                            email={firstOrder?.email}
                                            display="inline"
                                        >
                                            reaching out to us
                                        </OrderManagementLink>
                                        {" "}with your order number{orders && orders.length > 1 ? "s" : ""}.
                                    </Text>
                                </ListItem>

                                <ListItem>
                                    <Text as="p" fontStyle="text02" fontWeight="regular" py={3}>
                                        <Highlight typeStyle="text03" fontWeight="black">
                                            2. Wait for your Alpha BRAIN<Sup variant="reg" /> to come in the mail.{" "}
                                        </Highlight>
                                        Orders typically ship within two to three business days of being placed. Once
                                        your order has shipped, you can expect a confirmation email with your tracking
                                        information so you can keep an eye on it from there.
                                    </Text>
                                </ListItem>

                                <ListItem>
                                    <Text as="p" fontStyle="text02" fontWeight="regular" py={3}>
                                        <Highlight typeStyle="text03" fontWeight="black">
                                            3. Follow us on social media and share your Alpha BRAIN<Sup
                                                variant="reg"
                                            /> experience with the world.{" "}
                                        </Highlight>
                                        You can find us @onnit on most platforms like{" "}
                                        <Text
                                            as="a"
                                            display="inline"
                                            href="https://www.instagram.com/onnit/"
                                            target="_blank"
                                        >
                                            Instagram
                                        </Text>,{" "}
                                        <Text
                                            as="a"
                                            display="inline"
                                            href="https://www.facebook.com/Onnit/"
                                            target="_blank"
                                        >
                                            Facebook
                                        </Text>,{" "}
                                        <Text as="a" display="inline" href="https://twitter.com/onnit/" target="_blank">
                                            Twitter
                                        </Text>,{" "}
                                        <Text
                                            as="a"
                                            display="inline"
                                            href="https://www.youtube.com/onnitlabs"
                                            target="_blank"
                                        >
                                            YouTube
                                        </Text>, and{" "}
                                        <Text
                                            as="a"
                                            display="inline"
                                            href="https://www.pinterest.com/onnit/"
                                            target="_blank"
                                        >
                                            Pinterest
                                        </Text>.
                                        Take a picture with a bottle of Alpha BRAIN<Sup variant="reg" /> and tag us!
                                    </Text>
                                </ListItem>
                            </StyledList>

                            <RefundBox firstOrder={firstOrder} display={["block", "block", "none"]} />
                        </Box>
                    </TwelveColumn>
                </Box>
            </Box>
            <Box bg={PageThemeConfig.creamWhite} mt={[6, 6, 8]}>
                <TwelveColumn>
                    <Box
                        gridColumn={["1 / -1", "1 / -1", "2 / span 10", "2 / span 10"]}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <FdaStatement
                            px={[4, 4, 4, 0]}
                            py={4}
                            textColor="grays.5"
                            receiptId={firstOrder?.receipt_id}
                            email={firstOrder?.email}
                        />
                    </Box>
                </TwelveColumn>
            </Box>
        </>
    );
};

export default OrderConfirmationPage;
