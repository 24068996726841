import { ReactNode } from "react";

export interface UpsellTemplate {
    intro: string;
    heading: string;
    body: ReactNode;
    documentTitle: string;
    item: {
        sku: ItemSku;
        imageUrl: string;
        bgColor: string;
        imageMaxWidth: number[];
        discountPercent: number;
        acceptText: string;
        description: string;
    };
}

export interface ClickBankOrderListResponse {
    orders: ClickBankOrder[];
    billing_descriptor: string;
}

export interface ClickBankOrder {
    receipt_id: string;
    email: string;
    first_name: string;
    last_name: string;
    affiliate_nickname: string | null;
    address_line1: string;
    address_line2: string | null;
    city: string;
    state: string;
    postal_code: string;
    country_code: string;
    phone: string | null;
    items: OrderItem[];
    totals: OrderTotals;
    currency_code: string;
    payment_method: string; // E.g. TEST, VISA, MSTR, AMEX, PYPL, DISC, etc
    is_upsell: boolean;
    created_at: string; // ISO-8601 format
}

interface OrderTotals {
    item_total: number;
    shipping_total: number;
    tax_total: number;
    grand_total: number;
}

interface OrderItem {
    sku: string;
    quantity: number;
    name: string;
}

export enum ItemSku {
    // -----[ Alpha BRAIN ]-----
    ONE_AB90 = "20000196",
    ONE_AB90_UAT = "UAT-20000196",
    THREE_AB90 = "20000089",
    THREE_AB90_UAT = "UAT-20000089",
    SIX_AB90 = "20000271",
    SIX_AB90_UAT = "UAT-20000271",
    ONE_AB90_UPSELL = "20000003",
    ONE_AB90_UPSELL_UAT = "UAT-20000003",
    TWO_AB90_UPSELL = "20000088",
    TWO_AB90_UPSELL_UAT = "UAT-20000088",
    THREE_AB90_UPSELL = "20000272",
    THREE_AB90_UPSELL_UAT = "UAT-20000272",
    SIX_AB90_UPSELL = "20000273",
    SIX_AB90_UPSELL_UAT = "UAT-20000273",
}

export enum UpsellFlowId {
    // -----[ Alpha BRAIN ]-----
    TWO_AB90_ONE_AB90 = 54818,
    TWO_AB90_ONE_AB90_UAT = 54644,
    THREE_AB90_ONE_AB90 = 54817,
    THREE_AB90_ONE_AB90_UAT = 54645,
    SIX_AB90_ONE_AB90 = 54816,
    SIX_AB90_ONE_AB90_UAT = 54646,
}
