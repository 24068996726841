import React from "react";
import { Text, TextProps } from "@onnit-js/ui/components/text/Text";

const Highlight: React.FC<React.PropsWithChildren<React.PropsWithChildren<TextProps>>> = ({ children, ...rest }) => (
    <Text typeStyle="text02" fontWeight="black" display="inline" {...rest}>
        {children}
    </Text>
);

export default Highlight;
