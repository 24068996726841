import React from "react";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import styled, { keyframes } from "styled-components";

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

/**
 * @see https://codepen.io/maoberlehner/pen/bQGZYB
 */
const SkeletonBox = styled.div`
  position: relative;
  overflow: hidden;
  background-color: #ececec;
  height: 100%;

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0));
    animation: ${shimmer} 1.5s infinite;
    content: '';
  }
`;

const LoadingSkeletonBox: React.FC<React.PropsWithChildren<React.PropsWithChildren<BoxProps>>> = ({ ...boxProps }) => (
    <Box height="1.3em" mb={1} {...boxProps}>
        <SkeletonBox />
    </Box>
);

export default LoadingSkeletonBox;
