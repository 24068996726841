import { Sup, Text } from "@onnit-js/ui/components/text";
import React from "react";
import AppEnvironmentEnum from "@onnit-js/ui/@types/enums/AppEnvironmentEnum";
import { ItemSku, UpsellTemplate } from "./@types";
import Highlight from "../pages/common/Highlight";
import PageThemeConfig from "./PageThemeConfig";

const IS_PRODUCTION = window.onnit_context?.environment === AppEnvironmentEnum.PRODUCTION;
const upsellIdsToTemplates = new Map<string, UpsellTemplate>([
    ["1x-alpha-brain-90", {
        intro: "One last thing before you leave though, we PROMISE.",
        heading: "Want an extra 15% off another bottle?",
        body: (
            <>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    Alright so you don't need two more bottles.
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    Maybe you're not the type to stock up on supplements.<br />
                    Maybe you want to test it out before you over-commit.<br />
                    Maybe you're just not interested in spending more money.
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    All valid reasons.
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    But we want to make sure you get the absolute best value out of this order, so how about we add another bottle for 15% off?
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    All you have to do is accept this offer and you'll <Highlight color={PageThemeConfig.blackish}>save $12 on an extra bottle</Highlight>.
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    Think of it like this: you'll get more time to see the amazing benefits that Alpha BRAIN<Sup variant="reg" /> has to offer.
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    That means more support for thinking more clearly under daily stress.<Sup variant="dagger" />
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    More support to focus on complex tasks.<Sup variant="dagger" />
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    More time "getting in the zone" before you come back to grab another bottle.<Sup variant="dagger" />
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    <Highlight color={PageThemeConfig.blackish}>Sound fair?</Highlight>
                </Text>
            </>
        ),
        item: {
            sku: IS_PRODUCTION
                ? ItemSku.ONE_AB90_UPSELL
                : ItemSku.ONE_AB90_UPSELL_UAT,
            imageUrl: "https://onnits3.imgix.net/authority/107.png?w=439&trim=auto",
            /* Xs, sm, med, lg, xl */
            imageMaxWidth: [250, 250, 250, 280, 280],
            bgColor: PageThemeConfig.tanish,
            description: "One bottle of Alpha Brain 90 count",
            discountPercent: 15,
            acceptText: "I want an extra bottle for $67.96",
        },
        documentTitle: "Get One Extra Bottle of Alpha BRAIN® for 15% Off!",
    }],
    ["2x-alpha-brain-90", {
        intro: "Wait! Before you go...",
        heading: "Want an extra two bottles for 20% off?",
        body: (
            <>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    We know you're going to love adding Alpha BRAIN<Sup variant="reg" /> into your daily routine to support thinking more clearly under
                    daily stress, focusing on complex tasks and "getting in the zone."<Sup variant="dagger" />
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    And because you'll love it so much, we want to help reduce shipping costs and time so you don't have to wait between orders.
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    To ensure you've always got supply for one of the best nootropics for supporting focus, memory, and concentration<Sup variant="dagger" />,
                    we want to give you <Highlight color={PageThemeConfig.blackish}>20% off two extra bottles</Highlight>.
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    {/* eslint-disable-next-line max-len */}
                    When you claim this offer, you'll save an extra <Highlight color={PageThemeConfig.blackish}>~$32</Highlight> and ensure that you'll be covered for
                    the next 3+ months with Alpha BRAIN<Sup variant="reg" />.
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    <Highlight color={PageThemeConfig.blackish}>Interested?</Highlight>
                </Text>
            </>
        ),
        item: {
            sku: IS_PRODUCTION
                ? ItemSku.TWO_AB90_UPSELL
                : ItemSku.TWO_AB90_UPSELL_UAT,
            imageUrl: "https://onnits3.imgix.net/authority/8260.png?w=440&trim=auto&fit=crop&trim-md=4",
            /* Xs, sm, med, lg, xl */
            imageMaxWidth: [250, 250, 340, 300, 400],
            bgColor: PageThemeConfig.goldish,
            description: "Two bottles of Alpha Brain 90 count",
            discountPercent: 20,
            acceptText: "I want two extra bottles for $127.92",
        },
        documentTitle: "Get Two Extra Bottles of Alpha BRAIN® for 20% Off!",
    }],
    ["3x-alpha-brain-90", {
        intro: "Wait! Before you go...",
        heading: "Want to grab three more bottles and double your order for 40% off?",
        body: (
            <>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    We know you're going to love adding Alpha BRAIN<Sup variant="reg" /> into your daily routine to support thinking more clearly under daily stress,
                    focusing on complex tasks, and "getting in the zone."<Sup variant="dagger" />
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    And because you'll love it so much, we want to help reduce shipping costs and time so you don't have to wait between orders.
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    To ensure you've always got supply to one of the best nootropics for supporting focus, memory, and concentration<Sup variant="dagger" />, we want to give
                    you <Highlight color={PageThemeConfig.blackish}>40% off three extra bottles</Highlight>.
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    {/* eslint-disable-next-line max-len */}
                    When you accept this offer, you'll save an extra <Highlight color={PageThemeConfig.blackish}>$95.94</Highlight> and ensure you'll be covered for the
                    next <Highlight color={PageThemeConfig.blackish}>6+ months</Highlight> with Alpha BRAIN<Sup variant="reg" />.
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    <Highlight color={PageThemeConfig.blackish}>Interested?</Highlight>
                </Text>
            </>
        ),
        item: {
            sku: IS_PRODUCTION
                ? ItemSku.THREE_AB90_UPSELL
                : ItemSku.THREE_AB90_UPSELL_UAT,
            imageUrl: "https://onnits3.imgix.net/authority/9176.png?trim=auto&fit=crop&w=900&h=900&fp-x=0.52&fp-y=0.93&fp-z=4",
            imageMaxWidth: [250, 250, 320, 300, 450],
            bgColor: PageThemeConfig.goldish,
            description: "Three bottles of Alpha Brain 90 count",
            discountPercent: 40,
            acceptText: "I want three extra bottles for $143.91",
        },
        documentTitle: "Get Three Extra Bottles of Alpha BRAIN® for 40% Off!",
    }],
    ["6x-alpha-brain-90", {
        intro: "Wait! Before you go...",
        heading: "Want to double your order for 35% off?",
        body: (
            <>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    We know you're going to love adding Alpha BRAIN<Sup variant="reg" /> into your daily routine to support thinking more clearly under daily stress,
                    focusing on complex tasks, and "getting in the zone."<Sup variant="dagger" />
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    And because you'll love it so much, we want to help reduce shipping costs and time so you don't have to wait between orders.
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    To ensure you've always got supply to one of the best nootropics for supporting focus, memory, and concentration<Sup variant="dagger" />,
                    we suggest getting a few extra bottles just to hold you over for a little longer.
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    But in this case, it's more than just a few extra bottles...
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    ...since you've already picked up six bottles, why not pick up six more?
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    We know that sounds like a lot but when the math is all laid out, it makes sense, especially if you're big on saving money.
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    Up until now, you've saved nearly <Highlight color={PageThemeConfig.blackish}>$144</Highlight> on your{" "}
                    <Highlight color={PageThemeConfig.blackish}>first three bottles
                    </Highlight>{" "}of Alpha BRAIN<Sup variant="reg" />. However, if you pick up <Highlight color={PageThemeConfig.blackish}>three more</Highlight> right now, your savings inflate.
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    You'll effectively save <Text as="span" display="inline" textTransform="uppercase">another</Text> <Highlight color={PageThemeConfig.blackish}>$167.89</Highlight> on your order.
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    The last thing we'd want is for you to find yourself running out of Alpha BRAIN<Sup variant="reg" /> and having to wait a week for your next order to arrive.
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    To ensure you've always got supply to one of the best nootropics for supporting focus, memory, and concentration<Sup variant="dagger" />, we want to give
                    you <Highlight color={PageThemeConfig.blackish}>35% off six extra bottles</Highlight>.
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    And hey, if you're not interested in holding onto enough Alpha BRAIN<Sup variant="reg" /> for the apocalypse, you can share the love with friends and family!
                </Text>
                <Text typeStyle="text02" fontWeight="regular" as="p" py={2}>
                    <Highlight color={PageThemeConfig.blackish}>Interested?</Highlight>
                </Text>
            </>
        ),
        item: {
            sku: IS_PRODUCTION
                ? ItemSku.SIX_AB90_UPSELL
                : ItemSku.SIX_AB90_UPSELL_UAT,
            imageUrl: "https://onnits3.imgix.net/authority/9177.png?&&trim=auto&fit=crop&trim-md=2",
            /* Xs, sm, med, lg, xl */
            imageMaxWidth: [350, 360, 390, 400, 510],
            bgColor: PageThemeConfig.goldish,
            description: "Six bottles of Alpha Brain 90 count",
            discountPercent: 35,
            acceptText: "I want six extra bottles for $311.81",
        },
        documentTitle: "Get Six Extra Bottles of Alpha BRAIN® for 35% Off!",
    }],
]);

export default upsellIdsToTemplates;
