import { useEffect } from "react";

const suffix = "Onnit";
const useDocumentTitle = (title: string): void => {
    useEffect(() => {
        document.title = [title, suffix].join(" | ");
        return () => {
            document.title = suffix;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default useDocumentTitle;
