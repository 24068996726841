import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import React from "react";
import { Text } from "@onnit-js/ui/components/text";

interface BadgeProps extends BoxProps {
    background: string;
    textColor: string;
    zIndex: number;
    fontSize: string[];
    badgeSize: string[];
}

const Badge: React.FC<React.PropsWithChildren<React.PropsWithChildren<BadgeProps>>> = (
    {
        background,
        textColor,
        zIndex,
        fontSize,
        badgeSize,
        children,
        ...rest
    }) => (
        <Box position="absolute" {...rest}>
            <Box
                bg={background}
                borderRadius="50%"
                height={badgeSize}
                width={badgeSize}
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyItems="center"
            >
                <Box zIndex={zIndex} mx="auto">
                    <Text
                        textTransform="uppercase"
                        fontSize={fontSize}
                        lineHeight={1}
                        color={textColor}
                        textAlign="center"
                        fontWeight="black"
                        typeStyle="text01"
                    >
                        {children}
                    </Text>
                </Box>
            </Box>
        </Box>
);

export default Badge;
