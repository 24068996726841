import { AxiosInstance, AxiosResponse } from "axios";
import { ClickBankOrderListResponse } from "./@types";

export default class ClickBankApiClient {
    constructor(
        private readonly axios: AxiosInstance
    ) {
    }

    async getSessionId(params: string, iv: string): Promise<{ session_id?: string }> {
        const response = await this.fetchGet("session", params, iv);
        return response.data;
    }

    async listOrders(params: string, iv: string): Promise<ClickBankOrderListResponse> {
        const response = await this.fetchGet("orders", params, iv);
        return response.data;
    }

    private fetchGet(uri: string, params: string, iv: string): Promise<AxiosResponse> {
        // Add a timestamp to prevent the browser from caching the request.
        return this.axios.get(uri, { params: { params, iv, timestamp: Date.now() } });
    }
}
