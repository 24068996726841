import React from "react";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import theme from "@onnit-js/ui/components/themes/light";
import GlobalStyle from "@onnit-js/ui/components/themes/GlobalStyle";
import Box from "@onnit-js/ui/components/box/Box";
import OnnitLogo from "@onnit-js/ui/components/logo/OnnitLogo";
import { ErrorBoundary } from "react-error-boundary";
import PageThemeConfig from "./domain/PageThemeConfig";
import clickBankService from "./domain/ClickBankService";

const PageContainer: React.FC<React.PropsWithChildren<React.PropsWithChildren>> = ({ children }) => (
    <ThemeProvider theme={theme}>
        <GlobalStyle />

        <Box
            bg={PageThemeConfig.goldish}
            pl={[5]}
            height={[50, 60]}
            display="flex"
            justifyContent={["flex-start", "center"]}
            alignItems={["center", "center"]}
        >
            <OnnitLogo />
        </Box>

        <ErrorBoundary
            fallbackRender={() => null}
            onError={(error) => clickBankService.handleFatalError(error.message)}
        >
            {children}
        </ErrorBoundary>
    </ThemeProvider>
);

export default PageContainer;
