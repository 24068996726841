import axios from "axios";
import ClickBankApiClient from "./ClickBankApiClient";
import { ClickBankOrderListResponse, UpsellTemplate } from "./@types";
import upsellIdsToTemplates from "./upsellIdsToTemplates";
import ClickBankUrlManager from "./ClickBankUrlManager";

export class ClickBankService {
    constructor(
        private readonly client: ClickBankApiClient,
        public readonly urlManager: ClickBankUrlManager,
    ) {
    }

    async getSessionId(params: string, iv: string): Promise<string> {
        const { session_id } = await this.client.getSessionId(params, iv);
        if (!session_id) {
            throw new Error("Session ID was not returned.");
        }
        return session_id;
    }

    async listOrders(params: string, iv: string): Promise<ClickBankOrderListResponse> {
        const response = await this.client.listOrders(params, iv);
        if (!response.orders) {
            throw new Error("Orders were not returned.");
        }
        return response;
    }

    getUpsellTemplate = (upsellId: string): UpsellTemplate | null => (
        upsellIdsToTemplates.get(upsellId) ?? null
    );

    handleFatalError(error: string): void {
        console.warn("Redirecting due to fatal error: %s", error);
        window.location.href = this.urlManager.FATAL_ERROR_REDIRECT_URI;
    }
}

// Singleton
export default new ClickBankService(
    new ClickBankApiClient(
        axios.create({
            baseURL: `${window.onnit_context.url.api}/clickbank/`,
            timeout: 30 * 1000
        })
    ),
    new ClickBankUrlManager(),
);
