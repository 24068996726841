import React from "react";
import styled from "styled-components";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import Box from "@onnit-js/ui/components/box/Box";
import { Img, Picture } from "@onnit-js/ui/components/image";
import { H3, Text, TextProps } from "@onnit-js/ui/components/text/Text";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";
import Button from "@onnit-js/ui/components/button/Button";
import { H2, List, ListItem, Sup } from "@onnit-js/ui/components/text";
import Embed from "@onnit-js/ui/components/video/Embed";
import AppEnvironmentEnum from "@onnit-js/ui/@types/enums/AppEnvironmentEnum";
import { PageProps } from "../../../PageRouter";
import useDocumentTitle from "../../../../../hooks/useDocumentTitle";
import config from "../../../../../config/config";
import useScrollToRef from "../../../../../hooks/useScrollToRef";
import { Accordion, AccordionItem } from "../../../../../components/common/Accordion";
import PageThemeConfig from "../../../domain/PageThemeConfig";
import Title from "../../common/Title";
import Highlight from "../../common/Highlight";
import Disclaimer from "./Disclaimer";
import AlphaBrainAuthorityImage from "./AlphaBrainAuthorityImage";
import clickBankService from "../../../domain/ClickBankService";
import { ItemSku, UpsellFlowId } from "../../../domain/@types";
import FdaStatement from "../../common/FdaStatement";
import OrderManagementLink from "../../common/OrderManagementLink";

const BuyBoxButton = styled(Button)`
    border-color: #161616;
    padding: 10px;

    .small-text {
        font-size: 12px !important;
    }
`;

const StyledList = styled(List)`
    padding-left: 0;

    li {
        list-style: none;
    }
`;

/* eslint-disable max-len, react/jsx-closing-tag-location */
const Citation: React.FC<React.PropsWithChildren<React.PropsWithChildren<TextProps>>> = ({ children, ...rest }) => (
    <Text py={1} typeStyle="text04" fontWeight="regular" fontSize="11px" color={PageThemeConfig.darkGrayish} {...rest}>
        {children}
    </Text>
);

interface FaqProps extends TextProps {
    question: string;
}

const FaqItem: React.FC<React.PropsWithChildren<React.PropsWithChildren<FaqProps>>> = ({ question, children }) => (
    <Accordion>
        <AccordionItem
            color={PageThemeConfig.blackish}
            title={question}
            fontSize={[3, 3, 4]}
            underline={false}
            maxWidth="1000px"
            pt={3}
            pb={3}
            my={2}
            style={{ borderBottom: `1px solid ${PageThemeConfig.tanish}` }}
        >
            <Box bg={PageThemeConfig.creamWhite}>
                <Box p={[4, 4, 4, 6]}>
                    <Text as="p" typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular">
                        {children}
                    </Text>
                </Box>
            </Box>
        </AccordionItem>
    </Accordion>
);

const { urlManager } = clickBankService;

const AlphaBrainPitchPage: React.FC<React.PropsWithChildren<React.PropsWithChildren<PageProps>>> = () => {
    useDocumentTitle("Alpha BRAIN® Special Offer");

    const onnitContext = useOnnitContext();
    const [buyBoxRef, scrollToBuyBox] = useScrollToRef();

    if (!onnitContext) {
        return null;
    }

    const IS_PRODUCTION = onnitContext.environment === AppEnvironmentEnum.PRODUCTION;
    const addToCartUrls = Object.freeze<{ [key: string]: string }>({
        ONE_AB90: IS_PRODUCTION
            ? urlManager.getAddToCartUrl(ItemSku.ONE_AB90, UpsellFlowId.TWO_AB90_ONE_AB90)
            : urlManager.getAddToCartUrl(ItemSku.ONE_AB90_UAT, UpsellFlowId.TWO_AB90_ONE_AB90_UAT),
        THREE_AB90: IS_PRODUCTION
            ? urlManager.getAddToCartUrl(ItemSku.THREE_AB90, UpsellFlowId.THREE_AB90_ONE_AB90)
            : urlManager.getAddToCartUrl(ItemSku.THREE_AB90_UAT, UpsellFlowId.THREE_AB90_ONE_AB90_UAT),
        SIX_AB90: IS_PRODUCTION
            ? urlManager.getAddToCartUrl(ItemSku.SIX_AB90, UpsellFlowId.SIX_AB90_ONE_AB90)
            : urlManager.getAddToCartUrl(ItemSku.SIX_AB90_UAT, UpsellFlowId.SIX_AB90_ONE_AB90_UAT),
    });
    const IMG_BASE_URL = onnitContext.url.cdn.static_images;
    const IMG_URL = `${IMG_BASE_URL}/landers/clickbank/lander/`;
    const { IMG_PARAMS } = config;

    return (
        <>
            <Box maxWidth="1920px" mx="auto">
                <Picture
                    lazyLoad={false}
                    src={[
                        `${IMG_URL}1-clickbank-sales-page-img-top-banner.png${IMG_PARAMS}`,
                        `${IMG_URL}1-clickbank-sales-page-img-top-banner-desktop.png${IMG_PARAMS}`,
                    ]}
                    alt="Woman typing on laptop."
                />

                <TwelveColumn display={["block", "block", "grid", "grid"]} py={6}>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 4", "1/ span 10", "2/ span 11"]} gridRow={1}>
                        <Box mb={[4, 6, 6]} px={4}>
                            <Text as="h1" typeStyle="text02" color={PageThemeConfig.darkGrayish} fontWeight="bold">Focus Where It Matters Most</Text>
                        </Box>
                    </Box>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 5", "1 / span 10", "2/ span 11"]} gridRow={2}>
                        <Title as="h2" maxWidth={560} px={4}>
                            Add This 10 Second Task To Your Wake Up Routine to Support A Shift In Daily Output.
                        </Title>
                    </Box>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 5", "1 / span 6", "2 / span 5"]} gridRow={3} pt={4}>
                        <Box px={4}>
                            <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} py={3} as="p">
                                Are you struggling to get through your work in a timely manner?
                            </Text>
                            <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} py={3} as="p">
                                Is it normal for you to feel mentally drained and less productive throughout the day?
                            </Text>
                            <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} py={3} as="p">
                                Does it feel like you can’t seem to focus and are constantly task-switching?
                            </Text>
                            <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} py={3} as="p">
                                Are you tired of pouring multiple cups of coffee throughout the day just to keep up?
                            </Text>
                        </Box>
                        <Box display={["block", "block", "none"]}>
                            <Img
                                my={3}
                                src={`${IMG_URL}2-clickbank-sales-page-img-top-hero.png${IMG_PARAMS}`}
                                alt="Man preparing a meal."
                                width={523}
                                height={356}
                            />
                        </Box>
                        <Box px={4}>
                            <Text typeStyle="text02" fontWeight="black" as="p" py={3}>
                                If you reluctantly answered <Highlight fontStyle="italic">"yes"</Highlight> to any of these questions, you should
                                probably keep reading
                                to discover the ultimate support nootropic for thinking more clearly under
                                stress, focusing on complex tasks, and <Highlight fontStyle="italic">"getting in the zone."</Highlight>{" "}<Text as="span" display="inline" fontWeight="regular"><Sup
                                    variant="dagger"
                                />
                                </Text>
                            </Text>
                        </Box>
                        <Box mt={6} px={[0, 0, 0, 4]} mx={["auto", "auto", "auto", "auto"]} textAlign={["center", "center", "center", "left"]}>
                            <H3 className="sr-only">Unlock your Alpha brain</H3>
                            <Button color={PageThemeConfig.goldish} textColor="black" onClick={() => scrollToBuyBox()}>
                                Unlock your Alpha BRAIN<Sup variant="reg" />
                            </Button>
                        </Box>
                    </Box>
                    <Box gridColumn={["1 / -1", "1 / -1", "7 / span 5", "8 / span 4"]} gridRow="2 / 4" display={["none", "none", "block"]}>
                        <Img
                            src={`${IMG_URL}2-clickbank-sales-page-img-top-hero.png${IMG_PARAMS}`}
                            alt="Man preparing a meal."
                            width={523}
                            height={356}
                        />
                    </Box>
                </TwelveColumn>

                <Box bg="black" py={6}>
                    <H2 className="sr-only">Am I losing my edge?</H2>
                    <TwelveColumn display={["block", "block", "grid", "grid"]}>
                        <Box gridColumn={["1 / -1", "1 / -1", "2 / span 5", "2 / span 5"]} display="flex" alignItems="center" justifyContent="center">
                            <Box p={4} maxWidth={[420, 420, 540]}>
                                <Img
                                    src={`${IMG_URL}3-clickbank-sales-page-img-persona-ellipse.png${IMG_PARAMS}`}
                                    alt="Woman overwhelmed by the day."
                                    width={450}
                                    height={539}
                                />
                            </Box>
                        </Box>
                        <Box
                            gridColumn={["1 / -1", "1 / -1", "7 / span 6", "8 / span 5", "8 / span 4"]}
                            display={["block", "flex"]}
                            alignItems="center"
                            justifyContent="center"
                            px={4}
                        >
                            <Box>
                                <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.creamWhite} as="p" py={3}>
                                    But before we pull back the curtain and tell you how to address these productivity zappers, you have to know what's causing this struggle in quality focus and
                                    why it's happening to begin with.
                                </Text>
                                <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.creamWhite} as="p" py={3}>
                                    We've all had that moment when we walk into a room and completely forget what we were doing there.
                                </Text>
                                <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.creamWhite} as="p" py={3}>
                                    We've all been in the middle of a conversation trying to remember a word that’s just on the tip of our tongue.
                                </Text>
                                <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.creamWhite} as="p" py={3}>
                                    And while the occasional brain fart is bound to happen every now and then, it's a frustrating experience that can have you thinking,
                                </Text>
                                <Text typeStyle="text02" fontStyle="italic" fontWeight="bold" color={PageThemeConfig.goldish}>

                                    "Am I losing my edge?"

                                </Text>
                                <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.creamWhite} as="p" py={3}>
                                    It’s possible that there’s a connection to aging, diet, even how sedentary our lifestyles have gotten, but there’s a more direct factor affecting us…we just
                                    have
                                    more responsibilities.
                                </Text>
                            </Box>
                        </Box>
                    </TwelveColumn>
                </Box>

                <TwelveColumn py={6}>
                    <Box
                        gridColumn={["1 / -1", "1 / -1", "2 / span 10"]}
                        px={[0, 0, 0, 4]}
                    >
                        <Embed title="Unlock your Alpha BRAIN" src="https://player.vimeo.com/video/861697364" />
                        <Box mt={6} mx="auto" textAlign="center">
                            <Button color={PageThemeConfig.goldish} textColor="black" onClick={() => scrollToBuyBox()}>
                                Unlock your Alpha BRAIN<Sup variant="reg" />
                            </Button>
                        </Box>
                    </Box>
                </TwelveColumn>

                <Box bg="black" py={6}>
                    <TwelveColumn display={["block", "block", "grid", "grid"]} bg="black">
                        <Box gridColumn={["1 / -1", "1 / -1", "1 / span 5", "2 / span 5", "2/ span 11"]} gridRow={2} px={4}>
                            <Title as="h2" color={PageThemeConfig.goldish} maxWidth={[300, 560]}>
                                The busier you are, the harder you fall.
                            </Title>
                        </Box>
                        <Box gridColumn={["1 / -1", "1 / -1", "1 / span 6", "2 / span 5", "2 / span 5"]} gridRow={3} pt={4}>
                            <Box>
                                <Box px={4}>
                                    <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.grayish} as="p" py={3}>
                                        It's no secret that with adulthood comes a myriad of tasks we have to own.
                                    </Text>
                                    <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.grayish} as="p" py={3}>
                                        We work full time jobs, we balance checkbooks, calculate our taxes, we take care of all our basic human needs, and we manage to do so with relative ease at
                                        first
                                        because
                                        it’s just one person.
                                    </Text>
                                    <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.grayish} as="p" py={3}>
                                        But the older we get, more and more gets thrown on our plates.
                                    </Text>
                                </Box>
                                <Box display={["block", "block", "none"]}>
                                    <Img
                                        my={3}
                                        src={`${IMG_URL}6-clickbank-sales-page-img--gaming-2.png${IMG_PARAMS}`}
                                        alt="Man gaming in virtual reality with confused friend"
                                        width={523}
                                        height={356}
                                    />
                                </Box>
                                <Box px={4}>
                                    <Text typeStyle="text02" fontStyle="italic" fontWeight="bold" color={PageThemeConfig.goldish} as="p" pb={3}>
                                        Sometimes the job gets bigger.<br />
                                        Rent turns into a mortgage.<br />
                                        Car payments get bigger.<br />
                                        There are more people to clothe and mouths to feed.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.grayish} fontWeight="regular" as="p" py={3}>
                                        And as we take on more responsibilities, stress, and busyness, it's common for healthy adults to experience a shift in focus.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.grayish} fontWeight="regular" as="p" py={3}>
                                        This can show up in our lives as occasional forgetfulness, difficulty with task switching, and a general feeling of overwhelm as a natural response to the
                                        demands
                                        of modern
                                        life.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.grayish} fontWeight="regular" as="p" py={3}>
                                        Now, there are a few reasons why our focus and mental clarity may occasionally suffer, one of the most common ones being occasional stress.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.grayish} fontWeight="regular" as="p" py={3}>
                                        But it doesn't have to be any one stressor that affects us, it could be your normal, everyday stressors.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.grayish} fontWeight="regular" as="p" py={3}>
                                        Research discussed in the The Handbook of Aging and Cognition found that the ability to focus and inhibit distracting information declines as part of normal,
                                        healthy
                                        aging.<Sup>1</Sup>
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.grayish} fontWeight="regular" as="p" py={3}>
                                        That piece of information alone seems pretty straightforward but here’s the kicker: the very same study also suggests that older adults were still able to
                                        maintain
                                        high
                                        levels of focus when motivated to do so.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.grayish} fontWeight="regular" as="p" py={3}>
                                        So even though healthy adults experience a normal dip in cognitive edge as they age, there’s still this opposing factor that can still help keep you in the
                                        driver
                                        seat.
                                    </Text>
                                </Box>

                                <Box display={["block", "block", "none"]}>
                                    <Img
                                        my={3}
                                        src={`${IMG_URL}5-clickbank-sales-page-img-gaming-1.png${IMG_PARAMS}`}
                                        alt="Man gaming in virtual reality with confused friend"
                                        width={523}
                                        height={356}
                                    />
                                </Box>
                                <Title as="h3" color={PageThemeConfig.goldish} maxWidth={[300, 560]} px={4} py={3}>
                                    So what stops motivation?
                                </Title>
                            </Box>
                        </Box>
                        <Box display={["none", "none", "block"]} gridColumn={["1 / -1", "1 / -1", "7 / span 5", "8 / span 4"]} mt={[6, 6, 0]} gridRow="3 / 4">
                            <Img
                                src={`${IMG_URL}5-clickbank-sales-page-img-gaming-1.png${IMG_PARAMS}`}
                                alt="Man gaming in virtual reality"
                                width={523}
                                height={356}
                            />

                            <Img
                                mt={6}
                                src={`${IMG_URL}6-clickbank-sales-page-img--gaming-2.png${IMG_PARAMS}`}
                                alt="Man gaming in virtual reality with confused friend"
                                width={523}
                                height={356}
                            />
                        </Box>
                    </TwelveColumn>

                    <TwelveColumn display={["block", "block", "grid", "grid"]} backgroundColor="black">
                        <Box gridColumn={["1 / -1", "1 / -1", "1 / span 6", "2 / span 5", "2 / span 5"]} px={4}>
                            <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.grayish} as="p" py={3}>
                                Well, this suggests that factors like stress and busyness, which can be known to reduce motivation, may be contributing to this decline in focus in aging adults.
                            </Text>
                            <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.grayish} as="p" py={3}>
                                And if that’s the case, it all starts to make more sense.
                            </Text>
                            <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.grayish} as="p" py={3}>
                                When you're faced with normal, everyday stress throughout the week, you just want to relax and not have to deal with the next task.
                            </Text>
                            <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.grayish} as="p" pt={3}>
                                While that sounds great, as a healthy and responsible adult, you know that’s not always an option. You can’t just stop—you need to get stuff done and get through
                                the
                                day.
                            </Text>

                        </Box>
                    </TwelveColumn>
                </Box>

                <Box display={["block", "block", "none"]} my={6} mx="auto" textAlign={["center"]}>
                    <Button color="black" textColor="white" onClick={() => scrollToBuyBox()}>
                        Unlock your Alpha BRAIN<Sup variant="reg" />
                    </Button>
                </Box>

                <TwelveColumn display={["block", "block", "grid", "grid"]} py={6} bg={PageThemeConfig.goldish}>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 6", "2/ span 11"]} gridRow={1}>
                        <Box px={4} mb={[4, 6, 6]}>
                            <Text as="h2" typeStyle="text02" fontWeight="bold">But Stress Isn't The Only Culprit...</Text>
                        </Box>
                    </Box>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 6", "2 / span 5", "2/ span 11"]} gridRow={2}>
                        <Title as="h2" color={PageThemeConfig.blackish} px={4}>
                            We can't give all the credit to everyday stress for this dip in focus.
                        </Title>
                    </Box>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 6", "2 / span 5", "2 / span 5"]} gridRow={3} pt={4}>
                        <Box px={4}>
                            <Text typeStyle="text02" fontWeight="regular" as="p" py={3}>
                                In fact, we could point the finger in many different directions.
                            </Text>
                            <Text typeStyle="text02" fontWeight="regular" as="p" py={3}>
                                Some would say it’s poor eating habits, others could make a compelling argument for having a sedentary lifestyle, but one that seems to stick out like a sore thumb is
                                our increasingly busy and technology driven lifestyles.
                            </Text>
                        </Box>

                        <Box display={["block", "block", "none"]} my={3}>
                            <Img
                                src={`${IMG_URL}7-clickbank-sales-page-img-weight-lift-1.png${IMG_PARAMS}`}
                                alt="Man lifting barbell."
                                width={523}
                                height={356}
                                aria-hidden="true"
                            />
                        </Box>

                        <Box px={4}>
                            <Text typeStyle="text02" fontWeight="regular" as="p" py={3}>
                                Believe it or not, the way our lives have shifted into requiring phones and computers for just about everything we do has some significant effects on our brains.
                            </Text>
                            <Text typeStyle="text02" fontWeight="regular" as="p" py={3}>
                                The constant barrage of notifications, emails, and other distractions can make it difficult to stay focused on any one task for an extended period of time. This can
                                lead to
                                task switching, which can also impair cognition and make it harder to get things done efficiently.
                            </Text>
                        </Box>

                        <Box display={["block", "block", "none"]} my={3}>
                            <Img
                                src={`${IMG_URL}8-clickbank-sales-page-img-weight-lift-2.png${IMG_PARAMS}`}
                                aria-hidden="true"
                                alt="Man lifting barbell."
                                width={523}
                                height={356}
                            />
                        </Box>

                        <Box px={4}>
                            <Text typeStyle="text02" fontWeight="regular" as="p" py={3}>
                                A study published in PLOS ONE found that participants who used their smartphones more frequently had poorer attentional control (the ability to focus on a specific task
                                and ignore distractions).<Sup>2</Sup>
                            </Text>

                            <Text typeStyle="text02" fontWeight="regular" as="p" py={3}>
                                It's clear we need to limit distractions and interruptions to maintain the focus we're looking for.
                            </Text>

                            <Text typeStyle="text02" fontWeight="black" fontStyle="italic" as="p" py={3}>
                                But let's be realistic for a second, that's not always possible.
                            </Text>
                        </Box>

                        <Box display={["block", "block", "none"]} my={3}>
                            <Img
                                src={`${IMG_URL}9-clickbank-sales-page-img-weight-lift-3.png${IMG_PARAMS}`}
                                alt="Man lifting barbell."
                                aria-hidden="true"
                                width={523}
                                height={356}
                            />
                        </Box>

                        <Box px={4}>
                            <Text typeStyle="text02" fontWeight="regular" as="p" py={3}>
                                Sure, we can limit distractions for the first hour in the morning and probably for the last 2 hours before bedtime, but what about the other 13 hours of the day?
                            </Text>

                            <Text typeStyle="text02" fontWeight="regular" as="p" py={3}>
                                Our schedules are on phones and computers. We use text and email to communicate with both family and work. And yes, social media and streaming services can be sucking
                                away our time, but we all decompress in different ways, don’t we?
                            </Text>
                            <Text typeStyle="text02" fontWeight="regular" as="p" py={3}>
                                Point being, limiting distractions is possible but not probable, especially when our lives rely so heavily on technology to exist in a modern world.
                            </Text>
                        </Box>

                    </Box>
                    <Box
                        display={["none", "none", "block"]}
                        gridColumn={["1 / -1", "1 / -1", "7 / span 5", "8 / span 4"]}
                        gridRow="3 / 4"
                        pb={3}
                    >
                        <Img
                            src={`${IMG_URL}7-clickbank-sales-page-img-weight-lift-1.png${IMG_PARAMS}`}
                            alt="Man lifting barbell."
                            width={523}
                            height={356}
                        />
                        <Img
                            src={`${IMG_URL}8-clickbank-sales-page-img-weight-lift-2.png${IMG_PARAMS}`}
                            mt={6}
                            aria-hidden="true"
                            alt="Man lifting barbell."
                            width={523}
                            height={356}
                        />
                        <Img
                            mt={6}
                            src={`${IMG_URL}9-clickbank-sales-page-img-weight-lift-3.png${IMG_PARAMS}`}
                            alt="Man lifting barbell."
                            aria-hidden="true"
                            width={523}
                            height={356}
                        />
                    </Box>
                </TwelveColumn>

                <TwelveColumn display={["block", "block", "grid", "grid"]} pt={6}>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 6", "2/ span 11"]} gridRow={1}>
                        <Box px={4} mb={[4, 6, 6]}>
                            <Text as="h2" typeStyle="text02" color={PageThemeConfig.darkGrayish} fontWeight="bold">Focus Where It Matters Most</Text>
                        </Box>
                    </Box>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 5", "2 / span 5", "2/ span 11"]} gridRow={2}>
                        <Title as="h3" maxWidth={[400, 400, 500, 400, 560]} px={4}>
                            So how do you get that edge back?
                        </Title>
                    </Box>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 6", "2 / span 5", "2 / span 5"]} gridRow={3} pt={4} px={4}>
                        <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} as="p" py={3}>
                            It can be frustrating to hear that even if you’re a health conscious person who takes care of themself, there’s a chance you can be losing your edge.
                        </Text>
                        <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} as="p" py={3}>
                            The edge that had you immediately energized and waking up early without having to hit the snooze button. The edge that made you a quick witted conversationalist.
                        </Text>
                        <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} as="p" py={3}>
                            The edge that really made you a top performer at work who got all major projects and responsibilities.
                        </Text>
                        <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} as="p" py={3}>
                            And while you’re not constantly forgetting your keys or blanking on the names of your new coworkers, there are moments where you feel like you’re a computer that needs
                            the latest software update.
                        </Text>
                        <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} as="p" py={3}>
                            Sure, it’s not the end of the world if you have to wait for a video to buffer but it’s annoying as hell everytime it starts up only to stop a few seconds later.
                        </Text>
                        <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} as="p" py={3}>
                            Eventually you may lose patience and start beating yourself up about it.
                        </Text>
                        <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} as="p" py={3}>
                            Listen, you’re well within your right to be bothered with the situation at hand.
                        </Text>
                        <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} as="p" py={3}>
                            It's not like one day you just said "hey, bring on all the stress and distractions."
                        </Text>

                        <Box gridColumn={["1 / -1", "1 / -1", "1 / span 7", "2 / span 5", "2/ span 11"]}>
                            <Title as="h3" color={PageThemeConfig.darkGrayish} pt={2}>
                                Life happens.{" "}<br />
                                Technology{" "}<br />evolves.
                            </Title>
                        </Box>
                    </Box>
                    <Box display={["none", "none", "block"]} gridColumn={["1 / -1", "1 / -1", "7 / span 5", "8 / span 4"]} gridRow="3 / 4">
                        <Img
                            src={`${IMG_URL}10-clickbank-sales-page-img-workout-1.png${IMG_PARAMS}`}
                            alt="Man lifting barbell."
                            width={523}
                            height={356}
                        />
                        <Img
                            mt={6}
                            src={`${IMG_URL}11-clickbank-sales-page-img-workout-2.png${IMG_PARAMS}`}
                            alt="Woman drinking protein shake."
                            width={523}
                            height={356}
                        />
                    </Box>
                </TwelveColumn>

                <TwelveColumn display={["block", "block", "grid", "grid"]} mb={5} pt={1}>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 6", "2 / span 5", "2 / span 4"]} gridRow={3} px={4}>
                        <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} as="p" py={3}>
                            I mean sure, you could go live in a cave and totally seclude yourself from civilization, but where’s the fun in that?
                        </Text>
                        <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} as="p" py={3}>
                            If you want to exist in a modern world, these are just things we have to deal with.
                        </Text>
                        <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} as="p" py={3}>
                            So that begs the question: How do we deal with it?
                        </Text>
                        <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} as="p" pt={3}>
                            Well, you’ve got some options here.{" "}
                            <Text as="span" pt={3} display="block" typeStyle="text02" fontWeight="black" fontStyle="italic" color={PageThemeConfig.blackish}>
                                You could:
                            </Text>
                        </Text>
                    </Box>
                </TwelveColumn>

                <TwelveColumn display={["block", "block", "grid", "grid"]} pb={[6, 6, 6, 8]} px={4}>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 12", "2 / span 10"]} gridRow={4}>
                        <Box bg="#E8E5DC" height="2px" width="100%" minWidth="80px" mt={1} />
                        <Accordion>
                            <AccordionItem
                                defaultOpen
                                pt={3}
                                fontWeight="black"
                                textTransform="uppercase"
                                color={PageThemeConfig.darkGrayish}
                                title="Change Your Diet and Lifestyle Habits:"
                                fontSize={[5, 5, 5, 8]}
                                underline={false}
                                maxWidth="1000px"
                            >
                                <Box px={2}>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" py={3}>
                                        Not a bad idea. It wouldn't hurt to shift your eating habits to include more vegetables. You could also improve your sleep hygiene or add practices like yoga
                                        and meditation into your daily routine.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" py={3}>
                                        In fact, we'd suggest trying these things if you haven't already.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" py={3}>
                                        Still, these are long term solutions, they take time to affect a change and see results.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" py={3}>
                                        So chances are you’re not going to see noticeable differences in your daily productivity or outputs within a few weeks.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" py={3}>
                                        Beyond that, changing eating and lifestyle habits isn’t easy to do. It requires time, effort, and money to create the consistency needed to get the benefits
                                        you’re looking for.
                                    </Text>
                                </Box>
                            </AccordionItem>
                        </Accordion>

                        <Box bg={PageThemeConfig.tanish} height="2px" width="100%" minWidth="80px" mt={1} />
                        <Accordion>
                            <AccordionItem
                                pt={3}
                                fontWeight="black"
                                textTransform="uppercase"
                                color={PageThemeConfig.darkGrayish}
                                title="Load Up On Caffeine:"
                                fontSize={[5, 5, 5, 8]}
                                underline={false}
                                maxWidth="1000px"
                            >
                                <Box px={2}>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" py={3}>
                                        There are tons of studies to support the effectiveness of caffeine along with a handful of health benefits when taken in safe amounts.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" py={3}>
                                        The Mayo Clinic claims 400 milligrams as the upper safe limit for consumption which equates to about four cups of coffee.<Sup>3</Sup>
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" py={3}>
                                        However, that's four cups of a small coffee (8oz equates to 95 mg of caffeine). In most cases, a small in America is 12oz which puts you 180mg above the
                                        recommended amount.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" py={3}>
                                        Drinking lots of coffee can make you feel worse later, and the cost of regular coffee shop visits adds up. A cup averages $2.70 in the U.S!<Sup>19</Sup>
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" py={3}>
                                        Look, caffeine is a great focus tool, but it's only a partial solution.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" py={3}>
                                        Is it worth it to deal with the ups and downs of caffeine and burn a hole in your pocket just to get more work done?
                                    </Text>
                                </Box>
                            </AccordionItem>
                        </Accordion>

                        <Box bg={PageThemeConfig.tanish} height="2px" width="100%" minWidth="80px" mt={1} />
                        <Accordion>
                            <AccordionItem
                                pt={3}
                                fontWeight="black"
                                textTransform="uppercase"
                                color={PageThemeConfig.darkGrayish}
                                title="Look To Supplementation:"
                                fontSize={[5, 5, 5, 8]}
                                underline={false}
                                maxWidth="1000px"
                            >
                                <Box px={2}>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} as="p" py={3} fontWeight="regular">
                                        Supplementation is probably one of your best bets if you want your edge back. And in most cases, you’re probably already doing it to some degree.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} as="p" py={3} fontWeight="regular">
                                        Whether you use protein powder to hit your macros, omega 3 fatty acids for heart health, or magnesium to support countless bodily functions, they’re an easy way
                                        to
                                        stay on top of your game.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} as="p" py={3} fontWeight="regular">
                                        Now there are tons of supplements to choose from, the industry is at about $39 billion in the US alone.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} as="p" py={3} fontWeight="regular">
                                        So the question becomes, what supplements should I try out?
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} as="p" py={3} fontWeight="regular">
                                        Well, the first place to go, especially if we’re talking about supporting improved focus, cutting out distractions, and performing well under stress, is
                                        nootropics.
                                    </Text>
                                </Box>
                            </AccordionItem>
                        </Accordion>
                    </Box>
                </TwelveColumn>

                <Box bg="black">
                    <Img
                        placeholder={[1533, 460]}
                        src={`${IMG_URL}12-clickbank-sales-page-img-break-banner.png${IMG_PARAMS}`}
                        alt="Bottle of Alpha Brain on desk"
                    />
                    <Box py={[4]} mx="auto" textAlign="center" display={["block", "block", "none"]}>
                        <Button color={PageThemeConfig.goldish} textColor="black" onClick={() => scrollToBuyBox()}>
                            Unlock your Alpha BRAIN<Sup variant="reg" />
                        </Button>
                    </Box>
                </Box>

                <TwelveColumn display={["block", "block", "grid", "grid"]} pt={6}>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 6", "2/ span 11"]} gridRow={1}>
                        <Box px={4} mb={[4, 6, 6]}>
                            <Text as="h2" typeStyle="text02" color={PageThemeConfig.darkGrayish} fontWeight="bold">Not all nootropics are created equal</Text>
                        </Box>
                    </Box>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 6", "2 / span 5", "2/ span 11"]} gridRow={2}>
                        <Title as="h3" maxWidth={[300, 560]} px={4}>
                            What are nootropics?
                        </Title>
                    </Box>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 9", "2 / span 6"]} gridRow={3} pt={4}>
                        <Box px={4}>
                            <Text typeStyle="text02" fontWeight="regular" as="p" py={3}>
                                Nootropics, also known as cognitive enhancers, are substances that can support cognitive function, memory, attention, concentration, and focus in healthy
                                individuals.
                            </Text>
                            <Text typeStyle="text02" fontWeight="regular" as="p" py={3}>
                                Some people use nootropics to improve their academic and professional performance, while others use them to enhance their creativity and artistic abilities.
                            </Text>
                            <Text typeStyle="text02" fontWeight="regular" as="p" py={3}>
                                While not all have been extensively studied, the newfound popularity of nootropics has caused a growing body of research to emerge that supports the effectiveness and
                                benefits of many popular nootropics.
                            </Text>
                        </Box>

                        <Box bg={PageThemeConfig.tanish} mx={4} p={5} mt={4}>
                            <Text typeStyle="text02" fontWeight="black" as="p" py={3}>
                                A study done at Northumbria University found that combining two of the most popular nootropics, caffeine and L-theanine, improved cognitive performance and mood
                                compared to placebo, with significant improvements in attention switching, alertness, and calmness.<Sup variant="dagger" /><Sup>4</Sup>
                            </Text>
                            <Text typeStyle="text02" fontWeight="black" as="p" py={3}>
                                Scientists in a Cambridge University study found that taking Omega-3 fatty acids improved blood flow in the brain during cognitive tasks and improved cognitive
                                function, particularly in working memory and reaction time.<Sup>5</Sup>
                            </Text>

                        </Box>

                        <Box px={4}>
                            <Text typeStyle="text02" fontWeight="regular" as="p" py={3} mt={4}>
                                All to say, there’s clear correlation between nootropics and supporting brain function and focus in healthy people.
                            </Text>
                            <Text typeStyle="text02" fontWeight="regular" as="p" py={3}>
                                But remember what we said about caffeine earlier? It’s a great tool but requires the right dosage and isn't for everyone.
                            </Text>
                        </Box>
                    </Box>
                </TwelveColumn>

                <TwelveColumn display={["block", "block", "grid", "grid"]} pt={4} pb={6}>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 8", "2 / span 5", "2/ span 11"]} gridRow={2} px={4}>
                        <Title color={PageThemeConfig.darkGrayish}>
                            Not all nootropics are created equal.
                        </Title>
                    </Box>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 9", "2 / span 6"]} gridRow={3} px={4} pt={4}>
                        <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} as="p" pb={3}>
                            Like any substance, they should be researched and always used responsibly.
                        </Text>
                        <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} as="p" py={3}>
                            But you don’t have the time to do all that research and testing to see which nootropics may be best for you, do you? You have other priorities to deal with.
                        </Text>
                        <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} as="p" py={3}>
                            Fact of the matter is, you want a strategy that works right now. You want all the research in hand and you want to be at the top of your game now rather than later.
                        </Text>
                        <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.blackish} as="p" py={3}>
                            Luckily, we have a strategy for you...
                        </Text>
                    </Box>
                </TwelveColumn>

                <Box bg={PageThemeConfig.blackish} py={6}>
                    <Box
                        display={["block", "block", "grid"]}
                        gridTemplateColumns="1fr 1fr"
                        px={4}
                    >
                        <Box gridColumn="span 1">
                            <TwelveColumn>
                                <Box gridColumn={["1 / -1", "1 / -1", "1 / span 12", "3 / span 11", "3 / span 9"]}>
                                    <Title as="h2" color={PageThemeConfig.goldish} maxWidth={[300, 560]}>
                                        Introducing...{" "}<br />
                                        Alpha{" "}BRAIN<Sup variant="reg" />
                                    </Title>

                                    <Text typeStyle="text02" color={PageThemeConfig.tanish} fontWeight="regular" as="p" py={3} mt={4}>
                                        Onnit's ultimate nootropic to stimulate focus, support sharp mental clarity, and help you stay on the ball.<Sup variant="dagger" />
                                    </Text>

                                    <Title as="h3" color={PageThemeConfig.tanish} pt={4} maxWidth={[400, 400, 400, 650]}>
                                        Alpha BRAIN<Sup variant="reg" />{" "}is unlike any other nootropic on the market.
                                    </Title>

                                    <Box pt={4}>
                                        <Text typeStyle="text02" color={PageThemeConfig.darkTanish} fontWeight="regular" as="p" py={3}>
                                            It’s specifically designed to help support peak performance so you’re at the top of your game.
                                        </Text>
                                        <Text typeStyle="text02" color={PageThemeConfig.darkTanish} fontWeight="regular" as="p" py={3}>
                                            If you want to prime your brain and support brain performance, you need to concentrate on two things:
                                        </Text>
                                        <Text typeStyle="text02" color={PageThemeConfig.darkTanish} fontWeight="regular" as="p" py={3}>
                                            1. Getting it to make more alpha waves<Sup variant="dagger" /><br />
                                            2. Supporting the production of neurotransmitters<Sup variant="dagger" />
                                        </Text>

                                        <Text typeStyle="text02" fontStyle="italic" fontWeight="black" color={PageThemeConfig.creamWhite} as="p" py={3}>
                                            Alpha BRAIN<Sup variant="reg" /> was formulated with both goals in mind.
                                        </Text>
                                        <Text typeStyle="text02" color={PageThemeConfig.darkTanish} fontWeight="regular" as="p" py={3}>
                                            With Onnit's engineered blends, Alpha BRAIN<Sup variant="reg" /> helps build an environment where the brain can operate on all cylinders, promoting
                                            mental clarity.<Sup variant="dagger" />
                                        </Text>
                                        <Text typeStyle="text02" color={PageThemeConfig.darkTanish} fontWeight="regular" as="p" py={3}>
                                            Alpha BRAIN<Sup variant="reg" /> also supports attention and helps to tune out distractions.<Sup variant="dagger" />
                                        </Text>
                                        <Text typeStyle="text02" color={PageThemeConfig.darkTanish} fontWeight="regular" as="p" py={3}>
                                            One of the coolest things about it is its ability to support alpha brain waves<Sup variant="dagger" /> (that's where we get the name from).
                                        </Text>
                                        <Text typeStyle="text02" color={PageThemeConfig.darkTanish} fontWeight="regular" as="p" py={3}>
                                            You know when you're <Text typeStyle="text02" color={PageThemeConfig.creamWhite} as="span" display="inline" fontStyle="italic">"in the zone"</Text> or in
                                            a <Text typeStyle="text02" color={PageThemeConfig.creamWhite} as="span" display="inline" fontStyle="italic">"flow state"</Text>? The aim is to create an
                                            environment for you to help maintain that flow state.<Sup variant="dagger" />
                                        </Text>

                                        <Text as="p" typeStyle="title01" color={PageThemeConfig.goldish} py={4} fontSize={[6, 5, 4, 6]} maxWidth={530} lineHeight={3}>
                                            It supports focus during demanding work and helps facilitate flow while already engaged in work.<Sup variant="dagger" />
                                        </Text>

                                        <Text typeStyle="text02" color={PageThemeConfig.darkTanish} fontWeight="regular" as="p" py={3}>
                                            So whether you're building or fixing something, working on a complex spreadsheet or technical document, engaged in a challenging activity or simply
                                            looking
                                            to check off your daily tasks — Alpha BRAIN<Sup variant="reg" /> can help sustain your attention and support productivity.<Sup variant="dagger" />
                                        </Text>
                                        <Text typeStyle="text02" color={PageThemeConfig.darkTanish} fontWeight="regular" as="p" py={3}>
                                            We all know how easy it is to get sidetracked when we're working on something, right? Well, Alpha BRAIN<Sup variant="reg" /> can help steer you back in
                                            the right direction.<Sup variant="dagger" />
                                        </Text>
                                        <Text typeStyle="text02" color={PageThemeConfig.darkTanish} fontWeight="regular" as="p" py={3}>
                                            It supports your ability to visually focus on a target during excess stimulation and decreases the tendency to respond to distractions.<Sup
                                                variant="dagger"
                                            />
                                        </Text>
                                        <Text typeStyle="text02" color={PageThemeConfig.darkTanish} fontWeight="regular" as="p" py={3}>
                                            Another major benefit is how Alpha BRAIN<Sup variant="reg" /> can promote relaxation and support healthy stress management.<Sup variant="dagger" />
                                        </Text>
                                        <Text typeStyle="text02" color={PageThemeConfig.darkTanish} fontWeight="regular" as="p" py={3}>
                                            Theanine, one of the key ingredients, supports a calming response in those more likely to experience daily stress.<Sup variant="dagger" />
                                        </Text>
                                        <Text typeStyle="text02" color={PageThemeConfig.darkTanish} fontWeight="regular" as="p" py={3}>
                                            So, whether you're dealing with a stressful project on a tight deadline or simply exist in a high-pressure environment, Alpha BRAIN<Sup
                                                variant="reg"
                                            />{" "}
                                            can help you stay cool and collected while keeping you alert and focused enough to produce.<Sup variant="dagger" />
                                        </Text>
                                        <Text typeStyle="text02" color={PageThemeConfig.darkTanish} fontWeight="regular" as="p" pt={3}>
                                            But most importantly this one-of-a-kind nootropic can also support cognitive function, memory, and mental clarity.<Sup variant="dagger" />
                                        </Text>

                                    </Box>
                                </Box>
                            </TwelveColumn>
                        </Box>

                        <Box gridColumn="span 1">
                            <TwelveColumn>
                                <Box gridColumn={["1 / -1", "1 / -1", "2 / span 10", "2 / span 10", "3 / span 8"]} justifyContent="center" justifyItems="center" alignContent="center">
                                    <Box mx="auto" textAlign="center" mt={4}>
                                        <Box maxWidth={[250, 250, 250, 325]} mx="auto" textAlign="center">
                                            <AlphaBrainAuthorityImage imgBase={IMG_BASE_URL} imgParams={`${IMG_PARAMS}&trim=auto`} />
                                        </Box>
                                        <Title fontSize={[6, 5, 8]} py={6} as="p" color={PageThemeConfig.goldish} pb={4} lineHeight={3}>
                                            It can help with:
                                        </Title>
                                        <StyledList>
                                            <ListItem color={PageThemeConfig.tanish} pt={6} typeStyle="text01" fontWeight="regular" fontSize={[4, 4, 5, 6]}>
                                                <Text as="span" color={PageThemeConfig.goldish} display="inline-block">•</Text>{" "}Verbal and working memory<Sup variant="dagger" />
                                            </ListItem>

                                            <ListItem color={PageThemeConfig.tanish} pt={6} typeStyle="text01" fontWeight="regular" fontSize={[4, 4, 5, 6]}>
                                                <Text as="span" color={PageThemeConfig.goldish} display="inline-block">•</Text>{" "}Recognition of surroundings due to being aware and paying
                                                attention<Sup
                                                    variant="dagger"
                                                />
                                            </ListItem>

                                            <ListItem color={PageThemeConfig.tanish} pt={6} typeStyle="text01" fontWeight="regular" fontSize={[4, 4, 5, 6]}>
                                                <Text as="span" color={PageThemeConfig.goldish} display="inline-block">•</Text>{" "}Cognitive function and processes<Sup variant="dagger" />
                                            </ListItem>

                                            <ListItem color={PageThemeConfig.tanish} pt={6} typeStyle="text01" fontWeight="regular" fontSize={[4, 4, 5, 6]}>
                                                <Text as="span" color={PageThemeConfig.goldish} display="inline-block">•</Text>{" "}Supporting attention, focus, pattern recognition, organizing
                                                information, reasoning, problem-solving<Sup variant="dagger" />
                                            </ListItem>

                                            <ListItem color={PageThemeConfig.tanish} pt={6} typeStyle="text01" fontWeight="regular" fontSize={[4, 4, 5, 6]}>
                                                Alpha BRAIN<Sup variant="reg" />{" "}is most literally built to help you stay mentally sharp and focused throughout the day.<Sup variant="dagger" />
                                            </ListItem>

                                        </StyledList>

                                        <Box mt={[8]} mx={["0", "auto", "auto", "0"]} textAlign="center">
                                            <Button color={PageThemeConfig.goldish} textColor="black" onClick={() => scrollToBuyBox()}>
                                                Unlock your Alpha BRAIN<Sup variant="reg" />
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </TwelveColumn>
                        </Box>
                    </Box>
                </Box>

                <TwelveColumn display={["block", "block", "grid", "grid"]} py={6} px={4}>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 10", "2 / span 8", "2/ span 11"]} gridRow={2}>
                        <Title as="h2" maxWidth={[350, 1000]}>
                            What's Inside Alpha BRAIN<Sup variant="reg" />{" "}That Makes It So Special?
                        </Title>
                    </Box>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 8", "2 / span 8", "2 / span 6"]} gridRow={3} pt={4}>
                        <Box>
                            <Text typeStyle="text02" fontWeight="regular" as="p" pb={3}>
                                We have four key ingredients that make Alpha BRAIN<Sup variant="reg" />{" "}the ultimate solution to promote deep focus, and support sharp mental clarity—
                                <Text display="inline" fontWeight="black">L-theanine, Vitamin B6, Huperzine A, and Bacopa monnieri.<Sup variant="dagger" /></Text>
                            </Text>
                            <Text typeStyle="text02" fontWeight="regular" as="p" pt={3}>
                                Let's take a deep dive into each one:
                            </Text>
                        </Box>

                        <Box bg={PageThemeConfig.goldish} p={5} my={6} ml={0}>
                            <Box>
                                <Title as="h3" maxWidth={1000} p={0}>
                                    L-theanine
                                </Title>
                                <Text typeStyle="text02" fontWeight="regular" py={3} as="p" mt={2}>
                                    L-theanine is a unique non-protein amino acid that was first found in green tea leaves and certain types of mushrooms. It was first isolated from green tea in
                                    the 1940s.
                                </Text>

                                <Text typeStyle="text02" fontWeight="regular" py={3} as="p">
                                    It's gained popularity in recent years for its ability to support alpha brain wave activity, which is associated with{" "}
                                    <Text display="inline" fontWeight="black">feelings of relaxation, focus, and flow state.</Text>{" "}
                                    Alpha brain waves have been suggested to boost problem-solving abilities and{" "}
                                    <Text display="inline" fontWeight="black">increase overall
                                        mental clarity.<Sup variant="dagger" />
                                    </Text>
                                </Text>

                                <Text typeStyle="text02" fontWeight="regular" py={3} as="p">
                                    Research found in the National Library of Medicine has also suggested that L-theanine has other potential benefits. One study found that L-theanine was
                                    effective in promoting a healthy response to normal, daily physiological and psychological stress.<Sup variant="dagger" /><Sup>18</Sup>
                                </Text>
                            </Box>
                        </Box>

                        <Box bg={PageThemeConfig.goldish} p={5} my={6} ml={0}>
                            <Box>
                                <Title as="h3" maxWidth={1000} p={0}>
                                    Vitamin B6
                                </Title>
                                <Text typeStyle="text02" fontWeight="regular" py={3} as="p" mt={2}>
                                    Vitamin B6 is a member of the vitamin B family, and is present in many foods including cereal grains, legumes, vegetables, liver, meat, and eggs.
                                </Text>

                                <Text typeStyle="text02" fontWeight="regular" py={3} as="p">
                                    Vitamin B6, also known as pyridoxine, is a water-soluble vitamin that plays a critical role in the body's metabolism.<Sup variant="dagger" />
                                </Text>

                                <Text typeStyle="text02" fontWeight="regular" py={3} as="p">
                                    Specifically, it is involved in the production of <Text display="inline" fontWeight="black">neurotransmitters</Text> such as{" "}
                                    <Text display="inline" fontWeight="black">serotonin, norepinephrine, and dopamine</Text>, which are important for supporting mood,
                                    behavior, and cognitive function.<Sup variant="dagger" />
                                </Text>

                                <Text typeStyle="text02" fontWeight="regular" py={3} as="p">
                                    In the body, vitamin B6 is converted to the coenzyme pyridoxal phosphate for a wide variety of metabolic reactions, such as carbohydrate and lipid metabolism.

                                </Text>
                            </Box>
                        </Box>

                        <Box bg={PageThemeConfig.goldish} p={5} my={6} ml={0}>
                            <Title as="h3" maxWidth={1000} p={0}>
                                Huperzine A
                            </Title>
                            <Text typeStyle="text02" fontWeight="regular" py={3} as="p" mt={2}>
                                Huperzine A is a chemical compound that exists in Huperzia serrata, a plant also known as ‘Toothed Clubmoss' or Chinese club moss.
                            </Text>

                            <Text typeStyle="text02" fontWeight="regular" py={3} as="p">
                                It's been used in traditional Chinese health practices for centuries and in recent years, it's gained popularity as a nootropic supplement due to its ability to support
                                cognitive function.<Sup variant="dagger" />
                            </Text>

                            <Text typeStyle="text02" fontWeight="regular" py={3} as="p">
                                One of the key benefits of Huperzine A is its ability to slow the breakdown of acetylcholine, a neurotransmitter that plays a critical role in learning, memory,
                                and attention.<Sup variant="dagger" />
                            </Text>

                            <Text typeStyle="text02" fontWeight="regular" py={3} as="p">
                                Acetylcholine is broken down by an enzyme called acetylcholinesterase, and Huperzine A works by inhibiting this enzyme.<Sup variant="dagger" />
                            </Text>

                            <Text typeStyle="text02" fontWeight="regular" py={3} as="p">
                                By slowing the breakdown of acetylcholine, Huperzine A{" "}<Text display="inline" fontWeight="black">supports cognitive function.<Sup variant="dagger" /></Text>

                            </Text>
                        </Box>

                        <Box bg={PageThemeConfig.goldish} p={5} my={6} ml={0}>
                            <Title as="h3" maxWidth={1000} p={0}>
                                Bacopa Monnieri Extract
                            </Title>
                            <Text typeStyle="text02" fontWeight="regular" py={3} as="p" mt={2}>
                                Bacopa monnieri, also known as water hyssop, is a common Indian plant used in traditional Ayurvedic practices.
                            </Text>

                            <Text typeStyle="text02" fontWeight="regular" py={3} as="p">
                                It has gained popularity in recent years as a{" "}
                                <Text display="inline" fontWeight="black">naturally-derived nootropic</Text>{" "}and{" "}
                                <Text display="inline" fontWeight="black">adaptogenic supplement</Text>{" "}due to its numerous benefits.<Sup variant="dagger" />
                            </Text>
                        </Box>

                        <Box>
                            <Text typeStyle="text02" mt={4} fontWeight="regular" as="p">
                                Modern day living is not only baseline stressful, but it's full of noisy distractions competing for your attention.
                            </Text>
                            <Text typeStyle="text01" fontSize={[6]} maxWidth={750} fontWeight="black" as="p" py={3} mt={2}>
                                What Alpha BRAIN<Sup variant="reg" />{" "}allows you to do is support the tuning out of some of that noise.<Sup variant="dagger" />
                            </Text>

                            <Text typeStyle="text02" fontWeight="regular" as="p" py={3}>

                                If your brain is a browser and you have 15 tabs open at any given moment, even if you're not paying attention to any of those particular tabs, there's still a part
                                of you that is processing those tasks—and that's siphoning off your energy.
                            </Text>

                            <Text typeStyle="text02" fontWeight="regular" as="p" py={3}>
                                What would your life look like if you were able to be fully present and locked in on the biggest tasks of your day for the time needed to focus on them?
                            </Text>

                            <Text typeStyle="text02" fontWeight="regular" as="p" py={3}>
                                With these four key ingredients added to your morning routine, you'll be able to start reclaiming control over your attention again.<Sup variant="dagger" />
                            </Text>

                            <Text typeStyle="text02" fontWeight="black" fontSize={3} pt={3} as="p">
                                And the best part? <Text display="inline" typeStyle="text02" fontWeight="black" fontStyle="italic" as="span" fontSize={3}>The Alpha BRAIN<Sup
                                    variant="reg"
                                />{" "}
                                    formula is caffeine free.
                                </Text>

                            </Text>
                        </Box>
                    </Box>
                </TwelveColumn>

                <Box bg={PageThemeConfig.goldish} pt={6}>
                    <Box
                        display={["block", "block", "grid"]}
                        gridTemplateColumns="1fr 1fr"
                    >
                        <Box gridColumn="span 1">
                            <TwelveColumn display={["block", "block", "grid", "grid"]} px={[4, 4, 4, 6]}>
                                <Box gridColumn={["1 / -1", "1 / -1", "1 / span 12", "3/ span 11"]} gridRow={1}>
                                    <Text as="h2" typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="bold">Focus Where It Matters Most</Text>
                                </Box>
                                <Box gridColumn={["1 / -1", "1 / -1", "1 / span 12", "3 / span 12", "3/ span 11"]} gridRow={2} mt={4}>
                                    <Title as="h3" color={PageThemeConfig.blackish}>
                                        Just think about how your days would go with Alpha BRAIN<Sup variant="reg" /> on your side
                                    </Title>
                                </Box>
                                <Box gridColumn={["1 / -1", "1 / -1", "1 / span 12", "3 / span 12", "3 / span 11"]} gridRow={3}>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" py={3}>
                                        Your mornings may no longer be filled with worry about endless tasks because you’d know upon waking up that you’d be covered.
                                    </Text>

                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" py={3}>
                                        With added support, deadlines could be hit, goals accomplished, and all because you have a leading nootropic people are raving about on your
                                        side.<Sup variant="dagger" />
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="black" as="p" py={3}>
                                        Take a look at what people from all over have to say about Alpha BRAIN<Sup variant="reg" />:
                                    </Text>

                                    <Box bg={PageThemeConfig.creamWhite} px={3} pt={4} pb={4} my={4}>
                                        <Box px={6}>
                                            <Text typeStyle="title02" color={PageThemeConfig.blackish} as="p">
                                                "Best thing since sliced bread! Changed my life! Take 2 every morning."
                                            </Text>
                                        </Box>
                                    </Box>
                                </Box>
                            </TwelveColumn>
                            <Disclaimer bgColor={PageThemeConfig.blackish} textColor={PageThemeConfig.creamWhite} display={["block", "block", "none"]} />
                        </Box>

                        <Box gridColumn="span 1">
                            <Box gridColumn={["1 / -1", "1 / -1", "1 / span 12", "8 / span 4"]} gridRow="3 / 4">
                                <Box maxWidth={[200, 200, 200, 325]} mx="auto" textAlign="center" mt={[6]}>
                                    <AlphaBrainAuthorityImage imgBase={IMG_BASE_URL} imgParams={`${IMG_PARAMS}&trim=auto`} />
                                </Box>
                                <Box pt={[4]} pb={[6]} mx={["auto", "auto", "auto", "0"]} textAlign="center">
                                    <Button color={PageThemeConfig.creamWhite} textColor="black" onClick={() => scrollToBuyBox()}>
                                        Unlock your Alpha BRAIN<Sup variant="reg" />
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Disclaimer bgColor={PageThemeConfig.blackish} textColor={PageThemeConfig.creamWhite} display={["none", "none", "block"]} mt={4} />
                </Box>

                <Box bg={PageThemeConfig.tanish} py={6}>
                    <Box
                        display={["block", "block", "grid"]}
                        gridTemplateColumns="1fr 1fr"
                    >
                        <Box gridColumn="span 1">
                            <TwelveColumn display={["block", "block", "grid", "grid", "grid"]}>
                                <Box gridColumn={["1 / -1", "1 / -1", "1 / span 12", "3 / span 11", "3 / span 7"]} gridRow={3} px={4}>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" pb={3}>
                                        Just think for a second, when you add Alpha BRAIN<Sup variant="reg" /> into your wellness routine, how much more could you get done?
                                    </Text>

                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" py={3}>
                                        How much stress would you be able to let go of when you combine this with your meditation and deep-breathing routine?
                                    </Text>

                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" py={3}>
                                        How much peace of mind would you have going to sleep if you were able to be that much more productive during the day?
                                    </Text>

                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="black" as="p" py={3}>
                                        That's what Alpha BRAIN<Sup variant="reg" />{" "}can help you with.<Sup variant="dagger" />
                                    </Text>

                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" pt={3} as="p">
                                        It's the ultimate nootropic for thinking more clearly under stress, allowing you to focus on tasks, and can support you when you’re already{" "}
                                        <Text typeStyle="text02" color={PageThemeConfig.blackish} as="span" display="inline" fontWeight="heavy">"in the zone"</Text>
                                        —helping you to stay zeroed in.<Sup variant="dagger" />
                                    </Text>
                                </Box>
                            </TwelveColumn>
                        </Box>

                        <Box display={["block", "block", "none"]}>
                            <Img
                                placeholder={[1533, 460]}
                                src={`${IMG_URL}bottle-lineup-mobile-hi-res.png${IMG_PARAMS}`}
                                alt="Bottles of Alpha Brain"
                                aria-hidden="true"
                            />
                        </Box>

                        <Box display={["none", "none", "block"]} gridColumn="span 1">
                            <Box display="flex" justifyContent="end">
                                <Box display="grid" maxWidth={850}>
                                    <Img
                                        placeholder={[1533, 460]}
                                        src={`${IMG_URL}bottle-lineup-desktop.png${IMG_PARAMS}`}
                                        alt="Bottles of Alpha Brain"
                                        aria-hidden="true"
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box bg={PageThemeConfig.creamWhite} py={6}>
                    <Box
                        display={["block", "block", "grid"]}
                        gridTemplateColumns="1fr 1fr"
                    >
                        <Box display={["none", "none", "block"]} gridColumn="span 1">
                            <Box gridColumn={["1 / -1", "1 / -1", "1 / span 12", "8 / span 4"]} gridRow="3 / 4">
                                <Box mx="auto" textAlign="center">
                                    <Img
                                        src={`${IMG_URL}13-clickbank-sales-page-img-coffee-cup.png${IMG_PARAMS}`}
                                        alt="Coffee upside down and spilling"
                                        width={888}
                                        height={1352}
                                    />
                                </Box>
                            </Box>
                        </Box>

                        <Box gridColumn="span 1">
                            <TwelveColumn display={["block", "block", "grid", "grid", "grid"]}>
                                <Box gridColumn={["1 / -1", "1 / -1", "1 / span 12", "2 / span 11", "3/ span 11"]} gridRow={2} mb={4}>
                                    <Title as="h3" maxWidth={[300, 550, 650]} px={4}>
                                        Your Key To Productivity And Focus For Less Than The Cost Of A Daily Coffee
                                    </Title>
                                </Box>
                                <Box gridColumn={["1 / -1", "1 / -1", "1 / span 12", "2 / span 11", "3 / span 9"]} gridRow={3} px={4}>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" py={3} as="p">
                                        Not only does it promote attention span and a relaxed state of alertness, it's cheaper than a cup of fancy coffee shop coffee.
                                    </Text>

                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" py={3} as="p">
                                        Like we said earlier, a cup of coffee averages about <Highlight color={PageThemeConfig.blackish}>$2.70.</Highlight><Sup>19</Sup>
                                    </Text>

                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" py={3} as="p">
                                        That can add up really quickly when you’re drinking <Highlight color={PageThemeConfig.blackish}>three</Highlight> to <Highlight>four</Highlight> cups a day,
                                        Monday through Friday.
                                    </Text>

                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" py={3} as="p">
                                        Let's say conservatively you're drinking <Highlight color={PageThemeConfig.blackish}>15 cups</Highlight> of coffee a week.<br /><Text
                                            typeStyle="text02"
                                            fontStyle="italic"
                                            as="span"
                                        >That's <Highlight
                                            color={PageThemeConfig.blackish}
                                        >$40.50
                                        </Highlight> a week or <Highlight color={PageThemeConfig.blackish}>$162</Highlight> a month you spend on coffee.
                                        </Text>
                                    </Text>

                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" py={3} as="p">
                                        On the other end, <Highlight color={PageThemeConfig.blackish}>90</Highlight> capsules of Alpha BRAIN<Sup variant="reg" /> would only cost you <Highlight
                                            color={PageThemeConfig.blackish}
                                        >$79.95
                                        </Highlight>.
                                    </Text>

                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" py={3} as="p">
                                        (Which means a single bottle would last up to a month and a half!)
                                    </Text>

                                    <Title px={0} as="h3" color={PageThemeConfig.darkGrayish} py={4} fontSize={[6, 5, 8]}>
                                        Though that’s a killer deal on it’s own, you’ll have the option to pay even less than that.
                                    </Title>

                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" py={3}>
                                        If you're someone who used to spend money on things like fancy coffees every day, this is almost like saving <Highlight
                                            color={PageThemeConfig.blackish}
                                        >49.3%
                                        </Highlight> on your monthly productivity spend.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" py={3}>
                                        However, for a limited time, we’re giving you the opportunity to bundle your bottles and save up to <Highlight
                                            color={PageThemeConfig.blackish}
                                        >30%
                                        </Highlight> on your
                                        entire purchase order.
                                    </Text>

                                    <Box display={["block", "block", "none"]}>
                                        <Img
                                            src={`${IMG_URL}13-clickbank-sales-page-img-coffee-cup.png${IMG_PARAMS}`}
                                            alt="Coffee upside down and spilling"
                                            width={888}
                                            height={1352}
                                        />
                                    </Box>

                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" as="p" py={3}>
                                        The only thing you have to do is wake up in the morning, take <Highlight color={PageThemeConfig.blackish}>two capsules</Highlight>{" "}
                                        with a glass of water and a light meal, then go on about your day as you normally would.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="bold" as="p" py={3}>
                                        <Text as="span" color={PageThemeConfig.goldish} display="inline-block">•</Text>{" "}
                                        Knock out the latest project that just landed on your desk.
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="bold" as="p" py={3}>
                                        <Text as="span" color={PageThemeConfig.goldish} display="inline-block">•</Text>{" "}
                                        Be cool as a cucumber when you're in those occasional stressful moments.<Sup variant="dagger" />
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="bold" as="p" py={3}>
                                        <Text as="span" color={PageThemeConfig.goldish} display="inline-block">•</Text>{" "}
                                        Leverage the support Alpha BRAIN<Sup variant="reg" />{" "}gives you to help zero in on tasks that comes your way<Sup variant="dagger" />
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="bold" as="p" py={3}>
                                        <Text as="span" color={PageThemeConfig.goldish} display="inline-block">•</Text>{" "}
                                        Have peace of mind knowing you have added support to help knock out your daily tasks.
                                    </Text>

                                    <Title as="h3" px={0} color={PageThemeConfig.darkGrayish} py={4} fontSize={[6, 5, 8]}>
                                        No Hidden Fees.
                                    </Title>

                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" py={3} as="p">
                                        And best of all? If you pick up <Highlight color={PageThemeConfig.blackish}>six</Highlight> bottles, you'll save <Highlight
                                            color={PageThemeConfig.blackish}
                                        >$143.91
                                        </Highlight> on your total
                                        order (that's <Highlight color={PageThemeConfig.blackish}>30% off</Highlight> the single bottle price)!
                                    </Text>
                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" py={3} as="p">
                                        It's a win-win all around.
                                    </Text>

                                    <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="regular" py={3} as="p">
                                        And after you try it out and love it like the <Highlight color={PageThemeConfig.blackish}>2 million+</Highlight> Alpha BRAIN<Sup variant="reg" /> users
                                        have,
                                        we're sure
                                        you'll be hooked.
                                    </Text>

                                    <Box mt={6} mx="auto" textAlign={["center", "center", "center", "center"]}>
                                        <Button color="black" textColor="white" onClick={() => scrollToBuyBox()}>
                                            Unlock your Alpha BRAIN<Sup variant="reg" /><br />and save up to $143.91 by bundling
                                        </Button>
                                    </Box>

                                </Box>
                            </TwelveColumn>
                        </Box>
                    </Box>
                </Box>

                <Box bg="black" py={6}>
                    <TwelveColumn display={["block", "block", "grid", "grid"]}>
                        <Box gridColumn={["1 / -1", "1 / -1", "2 / span 5", "2 / span 5"]} display={["block", "block", "flex"]} alignItems="center" justifyContent="center">
                            <Box display={["block", "block", "none"]} maxWidth={[300]} mx="auto" pt={4} pb={6}>
                                <Img
                                    src={`${IMG_URL}14-clickbank-sales-page-img-ab-bottle-ellipse.png${IMG_PARAMS}`}
                                    alt="Bottle of Alpha Brain"
                                    width={450}
                                    height={539}
                                />
                            </Box>
                            <Box px={[4, 4, 0]}>
                                <Box gridColumn={["1 / -1", "1 / -1", "1 / span 6", "2 / span 5", "3/ span 11"]} gridRow={2}>
                                    <Title as="h2" color={PageThemeConfig.goldish} maxWidth={750}>
                                        The Alpha BRAIN<Sup variant="reg" fontSize="0.3em" />{" "}Money Back Guarantee<Sup>††</Sup>
                                    </Title>
                                </Box>
                                <Box pt={4}>
                                    <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.creamWhite} as="p" py={3}>
                                        We're confident you'll love Alpha BRAIN<Sup variant="reg" />. However, we know it won't be for everyone. We all have different bodies and they all react to
                                        nootropics differently.
                                    </Text>
                                    <Text typeStyle="text02" fontWeight="regular" color={PageThemeConfig.creamWhite} as="p" py={3}>
                                        On the off chance it doesn't perform for you or you're not in love with our formula, we are happy to issue a {" "}
                                        <Text as="span" typeStyle="text02" display="inline" color={PageThemeConfig.goldish}>
                                            refund for the cost of the product upon return within 90 days of the purchase date.<Sup>††</Sup>
                                        </Text>{" "}
                                        We just ask that you try it out for at least two weeks to give it a fair shot. To start the return process, please{" "}
                                        <OrderManagementLink typeStyle="text02" display="inline">
                                            notify our team
                                        </OrderManagementLink>{" "}
                                        and tell us why it wasn't a fit for you.
                                    </Text>
                                    <Text typeStyle="text03" color={PageThemeConfig.goldish} as="p" py={3}>
                                        <Sup>††</Sup>{" "}To receive a refund, the remaining product must be returned within 90 days of the purchase date using a return label sent to your email.
                                        {" "}Initial shipping fees are non-refundable and return shipping is $7, which will be deducted from the final refund.
                                    </Text>
                                </Box>
                            </Box>
                        </Box>

                        <Box gridColumn={["1 / -1", "1 / -1", "7 / span 5", "8 / span 4"]} display={["none", "none", "flex"]} alignItems="center" justifyContent="center">
                            <Box p={[4, 4, 4, 0]}>
                                <Img
                                    src={`${IMG_URL}14-clickbank-sales-page-img-ab-bottle-ellipse.png${IMG_PARAMS}`}
                                    alt="Bottle of Alpha Brain"
                                    width={450}
                                    height={539}
                                />
                            </Box>
                        </Box>
                    </TwelveColumn>
                </Box>

                <TwelveColumn display={["block", "block", "grid", "grid"]} pt={6}>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 6", "2 / span 5", "2/ span 11"]} gridRow={1}>
                        <Text typeStyle="text02" color={PageThemeConfig.blackish} fontWeight="bold" px={4}>Ready To</Text>
                    </Box>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 6", "2 / span 5", "2/ span 11"]} gridRow={2}>
                        <Title as="h2" maxWidth={[350, 560]} px={4} pt={4}>
                            Unlock your Alpha BRAIN<Sup variant="reg" />?
                        </Title>
                    </Box>
                    <Box display={["block", "block", "none"]}>
                        <Img
                            src={`${IMG_URL}15-clickbank-sales-page-img-kitchen-1.png${IMG_PARAMS}`}
                            alt="Man preparing a meal"
                            width={523}
                            height={356}
                            my={6}
                        />
                    </Box>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 6", "2 / span 5", "2 / span 4"]} gridRow={3} mb={4}>
                        <Box px={4} pt={[0, 0, 4]}>
                            <Text as="p" display="inline" typeStyle="text02" fontWeight="heavy" color={PageThemeConfig.blackish} pb={3}>
                                You've got a few options:
                            </Text>

                            <Text typeStyle="text02" fontWeight="regular" py={3} color={PageThemeConfig.blackish} as="p">
                                <Text as="span" display="inline" fontWeight="bold" color={PageThemeConfig.blackish}>1.</Text>{" "}Continue to struggle and sit in frustration knowing you’re not at your
                                best. Only furthering you from your best self.
                            </Text>

                            <Text typeStyle="text02" fontWeight="regular" py={3} color={PageThemeConfig.blackish} as="p">
                                <Text as="span" display="inline" fontWeight="bold" color={PageThemeConfig.blackish}>2.</Text>{" "}Testing all the countless nootropics out there in the market that will
                                take
                                you ages to go through and potentially wasting your time.
                            </Text>

                            <Text typeStyle="text02" fontWeight="regular" py={3} color={PageThemeConfig.blackish} as="p">
                                <Text as="span" display="inline" fontWeight="bold" color={PageThemeConfig.blackish}>3.</Text>{" "}Or, you can try Alpha BRAIN<Sup variant="reg" /> today and get back on
                                top.
                            </Text>
                        </Box>

                        <Box display={["block", "block", "none"]}>
                            <Img
                                my={6}
                                src={`${IMG_URL}16-clickbank-sales-page-img-kitchen-2.png${IMG_PARAMS}`}
                                alt="Film shoot of chef in front of food truck"
                                width={523}
                                height={356}
                            />
                        </Box>

                        <Box px={4}>
                            <Text typeStyle="text02" fontWeight="regular" pt={-3} color={PageThemeConfig.blackish} as="p" py={3}>
                                Because right now you have an opportunity to get back to where you were not too long ago and possibly even improve on that version of yourself.
                            </Text>

                            <Text typeStyle="text02" fontWeight="regular" pt={-3} color={PageThemeConfig.blackish} as="p" py={3}>
                                It’s time to wake up excited and ready to take command of your day.
                            </Text>

                            <Text typeStyle="text02" fontWeight="regular" pt={-3} color={PageThemeConfig.blackish} as="p" py={3}>
                                To support your normal quick wit, sharp mind, and drive.<Sup variant="dagger" />
                            </Text>

                            <Text typeStyle="text02" fontWeight="regular" pt={-3} color={PageThemeConfig.blackish} as="p" py={3}>
                                It’s time to be the high performer you’ve always been and stay on top of all your major projects and responsibilities as a healthy, fully engaged adult.
                                <Sup variant="dagger" />
                            </Text>

                            <Text typeStyle="text02" fontWeight="regular" pt={-3} color={PageThemeConfig.blackish} as="p" py={3}>
                                Most importantly, it’s time to leverage Alpha BRAIN<Sup variant="reg" />, be at the top of your game, and help{" "}<Highlight color={PageThemeConfig.blackish}>keep your
                                    edge
                                </Highlight>.<Sup variant="dagger" />
                            </Text>

                            <Text typeStyle="text02" fontWeight="regular" pt={-3} color={PageThemeConfig.blackish} as="p" py={3}>
                                Are you ready to shift into third gear? Make the decision countless adults just like you have made and try Alpha BRAIN<Sup variant="reg" />.
                            </Text>
                        </Box>
                    </Box>

                    <Box display={["none", "none", "block"]} gridColumn={["1 / -1", "2 / span 5", "8 / span 4"]} gridRow="3 / 4">
                        <Img
                            src={`${IMG_URL}15-clickbank-sales-page-img-kitchen-1.png${IMG_PARAMS}`}
                            alt="Man preparing a meal"
                            width={523}
                            height={356}
                            mb={6}
                        />
                        <Img
                            my={6}
                            src={`${IMG_URL}16-clickbank-sales-page-img-kitchen-2.png${IMG_PARAMS}`}
                            alt="Film shoot of chef in front of food truck"
                            width={523}
                            height={356}
                        />
                    </Box>
                </TwelveColumn>
            </Box>

            <Box bg={PageThemeConfig.creamWhite} py={6}>
                <TwelveColumn>
                    <Box
                        bg={PageThemeConfig.goldish}
                        gridColumn={["1 / -1", "1 / -1", "2 / span 10", "3 / span 8"]}
                        mb={[0, 0, 0, 4]}
                    >
                        <Text typeStyle="text01" color={PageThemeConfig.blackish} px={[4, 4, 4, 10]} fontStyle="italic" textAlign="center" as="p" py={3}>
                            "These have helped [me] stay focus during my weekly studies!! Highly recommend!"
                        </Text>
                    </Box>
                </TwelveColumn>
            </Box>

            <Disclaimer bgColor={PageThemeConfig.goldish} textColor={PageThemeConfig.blackish} />

            <Box bg={PageThemeConfig.blackish} pb={6}>
                <Box maxWidth="1920px" mx="auto">
                    <Box bg={PageThemeConfig.blackish} py={6} ref={buyBoxRef}>
                        <Text as="h2" typeStyle="title01" fontSize={[8, 5, 8, 11]} color={PageThemeConfig.creamWhite} textAlign="center" mb={6} pt={4}>
                            Pick up your{" "}<br />discounted bottles today.
                        </Text>
                        <TwelveColumn
                            mx="auto"
                            textAlign="center"
                            gridColumnGap={[1, 1, 1, 4]}
                            gridRowGap={[4, 1, 1, 4]}
                            alignItems="center"
                        >
                            <Box
                                gridColumn={["1 / -1", "1 / -1", "5 / span 4", "5 / span 4", "5 / span 4"]}
                                justifyContent="center"
                                alignContent="center"
                                alignItems="center"
                                gridRow={1}
                            >
                                <Box bg={PageThemeConfig.goldish} borderRadius={["0px", "25px", "25px", "25px"]} py={[8, 8, 7, 8]}>
                                    <Box pb={3} pt={4}>
                                        <Text typeStyle="text01" fontWeight="black" fontSize={6}>
                                            BUY 3
                                        </Text>
                                        <Text typeStyle="text02" fontStyle="italic" mt={1} fontWeight="heavy" fontSize={[3]} px={4}>
                                            For $63.96 per bottle
                                        </Text>
                                    </Box>
                                    <Box maxWidth={[300, 300, 150, 190, 350]} textAlign="center" mx="auto" position="relative" p={4}>
                                        <Img
                                            src={`${IMG_URL}buybox/ab-90-3x@2x.png${IMG_PARAMS}`}
                                            alt="Three bottles of Alpha Brain 90 count"
                                            width={523}
                                            height={356}
                                        />
                                        <Box position="absolute" zIndex={100} top={0} right="5px" maxWidth={[300, 300, 55, 55, 500]}>
                                            <Img
                                                src={`${IMG_URL}buybox/badge-most-popular-creamwhite.svg${IMG_PARAMS}`}
                                                alt="Most Popular"
                                                width={523}
                                                height={356}
                                            />
                                            <Img
                                                mt={2}
                                                src={`${IMG_URL}buybox/badge-free-shipping.svg${IMG_PARAMS}`}
                                                alt="Free Shipping"
                                                width={523}
                                                height={356}
                                            />
                                        </Box>
                                    </Box>
                                    <Box mx="auto" textAlign="center" px={4} pt={3}>
                                        <BuyBoxButton width={["100%", "100%", "100%", "100%", "55%"]} color={PageThemeConfig.blackish} textColor="black" el="a" href={addToCartUrls.THREE_AB90}>
                                            <Text typeStyle="text03" fontSize={[3]} color={PageThemeConfig.creamWhite} as="span">I want 3 bottles</Text>
                                            <Text
                                                className="small-text"
                                                color={PageThemeConfig.creamWhite}
                                                typeStyle="text04"
                                                fontSize="11px"
                                                as="span"
                                                fontWeight="regular"
                                                fontStyle="normal"
                                            >(4 &frac12; month supply)
                                            </Text>
                                        </BuyBoxButton>
                                        <Text mt={3} className="small-text" typeStyle="text04" fontSize={1} fontStyle="italic" fontWeight="heavy" lineHeight={1.25}>
                                            You save $47.97<br />
                                            135 servings - Only $1.42 per day
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                gridColumn={["1 / -1", "1 / -1", "2 / span 3", "1 / span 4", "1 / span 4"]}
                                justifyContent="center"
                                alignContent="center"
                                alignItems="center"
                            >
                                <Box
                                    bg={PageThemeConfig.creamWhite}
                                    py={4}
                                    borderRadius={["0px", "25px", "25px", "25px"]}
                                >
                                    <Box pb={3} pt={4}>
                                        <Text typeStyle="text01" fontWeight="black" fontSize={6}>
                                            BUY 6
                                        </Text>
                                        <Text typeStyle="text02" pt={1} fontStyle="italic" fontWeight="heavy" fontSize={4} color={PageThemeConfig.blackish}>
                                            For $55.97 per bottle
                                        </Text>
                                    </Box>
                                    <Box maxWidth={500} textAlign="center" mx="auto" position="relative" p={4}>
                                        <Img
                                            src={`${IMG_URL}buybox/ab-90-6x@2x.png${IMG_PARAMS}`}
                                            alt="Six bottles of Alpha Brain 90 count"
                                            width={523}
                                            height={356}
                                        />
                                        <Box position="absolute" maxWidth={[300, 300, 55, 55, 500]} zIndex={100} top={0} right="25px">
                                            <Img
                                                src={`${IMG_URL}buybox/badge-best-deal-goldish.svg${IMG_PARAMS}`}
                                                alt="Most Popular"
                                                width={523}
                                                height={356}
                                            />
                                            <Img
                                                mt={2}
                                                src={`${IMG_URL}buybox/badge-free-shipping.svg${IMG_PARAMS}`}
                                                alt="Free Shipping"
                                                width={523}
                                                height={356}
                                            />
                                        </Box>
                                    </Box>
                                    <Box mx="auto" textAlign="center" px={4}>
                                        <BuyBoxButton width={["100%", "100%", "100%", "100%", "55%"]} color={PageThemeConfig.creamWhite} el="a" href={addToCartUrls.SIX_AB90}>
                                            <Text typeStyle="text03" fontSize={[3]} as="span" color={PageThemeConfig.blackish}>I want 6 bottles</Text>
                                            <Text className="small-text" typeStyle="text04" color={PageThemeConfig.blackish} fontSize="11px" as="span" fontWeight="regular" fontStyle="normal">
                                                (9 month supply)
                                            </Text>
                                        </BuyBoxButton>
                                        <Text mt={3} pb={2} className="small-text" typeStyle="text04" fontSize={1} fontStyle="italic" fontWeight="heavy" lineHeight={1.25}>
                                            You save $143.91<br />
                                            270 servings - Only $1.24 per day
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                gridColumn={["1 / -1", "1 / -1", "9 / span 3", "9 / span 4", "9 / span 4"]}
                                justifyContent="center"
                                alignContent="center"
                                alignItems="center"
                            >
                                <Box bg={PageThemeConfig.creamWhite} borderRadius={["0px", "25px", "25px", "25px"]} py={4}>
                                    <Box pb={3} pt={4}>
                                        <Text typeStyle="text01" fontWeight="black" fontSize={[6]}>
                                            BUY 1
                                        </Text>
                                        <Text typeStyle="text02" fontStyle="italic" pt={1} fontWeight="heavy" fontSize={3}>
                                            For $79.95
                                        </Text>
                                    </Box>
                                    <Box maxWidth={[300, 300, 150, 190, 300]} textAlign="center" mx="auto">
                                        <Img
                                            src={`${IMG_URL}buybox/ab-90-1x@2x.png${IMG_PARAMS}`}
                                            alt="One bottle of Alpha Brain 90 count"
                                        />
                                    </Box>
                                    <Box mx="auto" textAlign="center" px={4} pb={6} mt={[2, 2, 2, 6]} mb={3}>
                                        <BuyBoxButton width={["100%", "100%", "100%", "100%", "55%"]} color={PageThemeConfig.creamWhite} textColor="black" el="a" href={addToCartUrls.ONE_AB90}>
                                            <Text typeStyle="text03" fontSize={[3]} as="span">I want 1 bottle</Text>
                                            <Text className="small-text" typeStyle="text04" fontSize="11px" as="span" fontWeight="regular" fontStyle="normal">(45 servings)</Text>
                                        </BuyBoxButton>
                                    </Box>
                                </Box>
                            </Box>
                        </TwelveColumn>
                    </Box>
                </Box>
            </Box>

            <Box maxWidth="1920px" mx="auto" my={[4, 4, 7]} px={4}>
                <TwelveColumn>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 10", "2 / span 10", "2 / span 10"]}>
                        <Title as="h2" py={4}>
                            FAQ
                        </Title>
                        <FaqItem question="Is it gluten free?">
                            Alpha BRAIN<Sup variant="reg" />{" "}is in fact gluten free.
                        </FaqItem>

                        <FaqItem question="How long after taking it will I feel the effects?">
                            Results will vary per person. We recommend consistent use to allow your body to adjust and feel the full effects.
                        </FaqItem>

                        <FaqItem question="How does Alpha BRAIN® work?">
                            Alpha BRAIN<Sup variant="reg" />{" "}ingredients are handpicked based on research in cognitive science and our understanding of the brain supporting benefits of
                            nootropic ingredients.<Sup variant="dagger" />{" "}After assessing how these key ingredients can help support healthy brain function, Onnit's formulation
                            experts developed a unique proprietary nootropic formula for Alpha BRAIN<Sup variant="reg" />{" "}to "feed the brain".
                            <br /><br />
                            Just like with the heart, lungs, and muscles, the brain needs to be fed with nutrients to keep it strong and healthy. Alpha BRAIN<Sup variant="reg" />{" "}contains a
                            spectrum of ingredients
                            including amino acids, herbs, and compounds that in turn support memory and focus.<Sup variant="dagger" />
                        </FaqItem>

                        <FaqItem question="Can I take Alpha BRAIN® at night?">
                            Alpha BRAIN<Sup variant="reg" />{" "}is formulated to support focus and memory. And while you could take it for a late night study session, it does contain Vitamin
                            B6, so it may be best to take it earlier in the day.
                        </FaqItem>

                        <FaqItem question="Does Alpha BRAIN® contain caffeine?">
                            This is one of the great benefits of Alpha BRAIN<Sup variant="reg" />. Because unlike other cognitive supplements, it contains ZERO caffeine. This means you don’t
                            have to count it among your caffeine consumption for the day.
                        </FaqItem>

                        <FaqItem question="What is the daily recommended dosage?">
                            Alpha BRAIN<Sup variant="reg" />{" "}has a recommended serving of 2 capsules per day. If you’re new to nootropics, we suggest starting with 1 and then going from
                            there.
                        </FaqItem>

                        <FaqItem question="How do I know Alpha BRAIN® will work?">
                            Everyone's brains are different and achieving a high performing brain also involves having a healthy diet, getting plenty of sleep, and regular exercise, so
                            everyone's experiences can vary.
                        </FaqItem>

                        <FaqItem question="What if Alpha BRAIN® doesn't work for me? Can I get my money back?">
                            If that's the unfortunate case,{" "}
                            <OrderManagementLink display="inline">hit us up</OrderManagementLink>
                            {" "}for a refund! We just ask that you try it consistently for at least two weeks before requesting a refund and tell us about your
                            experience. Refunds are accepted within 90 days of the purchase date.
                            <br /><br />
                            The remaining product will need to be returned for a refund, and our Customer Service team can provide a return authorization label upon request. <Highlight
                                fontWeight="heavy"
                            >Initial
                                shipping charges are non-refundable and return shipping is $7, which will be deducted from the final refund.
                            </Highlight> Please{" "}
                            <OrderManagementLink typeStyle="text02" fontWeight="regular" display="inline">reach out</OrderManagementLink> to start the process.
                        </FaqItem>

                        <FaqItem question="How much is shipping and when will I get it?">
                            Shipping costs can vary on the items selected, but start at $7.65. These rates are calculated on package weight and the destination.
                            <br /><br />
                            All orders ship with UPS SurePost, a combination of UPS pickup and USPS completing delivery. Transit can take 7-10 days before it delivers, and transit times are
                            estimates and not guaranteed. There is a 2-4 business day processing period before orders are shipped with the carrier. At that
                            point, you can expect to receive tracking information by email.
                        </FaqItem>
                    </Box>
                </TwelveColumn>
            </Box>

            <Box bg={PageThemeConfig.goldish} py={6} px={[4, 4, 4, 0]}>
                <TwelveColumn>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 10", "1 / span 12", "2 / span 10"]}>
                        <FdaStatement textColor={PageThemeConfig.blackish} />
                    </Box>
                </TwelveColumn>
            </Box>

            <Box py={6} px={[4, 4, 4, 4, 0]}>
                <TwelveColumn>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / span 10", "1 / span 12", "2 / span 10"]}>
                        <Citation as="h2" fontWeight="bold">
                            Citations
                        </Citation>
                        <Citation>
                            1. Braver, T. S., & West, R. (2008). In F. I. M. Craik & T. A. Salthouse (Eds.), The Handbook of Aging and Cognition (3rd ed., pp. 311-372). Psychology Press.
                        </Citation>
                        <Citation>
                            2. Tanil CT, Yong MH (2020) PLOS ONE 15(8): e0219233.
                        </Citation>
                        <Citation>
                            3. Mayo Clinic Staff. (2022, March 19). Caffeine: How much is too much?. Mayo Clinic.
                        </Citation>
                        <Citation>
                            4. Haskell, C. F., Kennedy, D. O., Milne, A. L., Wesnes, K. A., & Scholey, A. B. (2008). Biological Psychology, 77(2), 113-122.
                        </Citation>
                        <Citation>
                            5. Jackson, P. A., Reay, J. L., Scholey, A. B., & Kennedy, D. O. (2012). British Journal of Nutrition, 107(8), 1093-1098.
                        </Citation>
                        <Citation>
                            6. Hidese S, Ogawa S, Ota M, Ishida I, Yasukawa Z, Ozeki M, Kunugi H.. 2019 Oct 3;11(10):2362. doi: 10.3390/nu11102362. PMID: 31623400; PMCID: PMC6836118.
                        </Citation>
                        <Citation>
                            8. Yoto A, Motoki M, Murao S, Yokogoshi H. J Physiol Anthropol. 2012 Oct 29;31(1):28. doi: 10.1186/1880-6805-31-28. PMID: 23107346; PMCID: PMC3518171.
                        </Citation>
                        <Citation>
                            9. Dodd FL, Kennedy DO, Riby LM, Haskell-Ramsay CF. Psychopharmacology (Berl). 2015 Jul;232(14):2563-76. Doi: 10.1007/s00213-015-3895-0. Epub 2015 Mar 13. PMID: 25761837;
                            PMCID: PMC4480845.
                        </Citation>
                        <Citation>
                            10. Parra M, Stahl S, Hellmann H. Cells. 2018 Jul 22;7(7):84. doi: 10.3390/cells7070084. PMID: 30037155; PMCID: PMC6071262.
                        </Citation>
                        <Citation>
                            11. Young SN. J Psychiatry Neurosci. 2007 Nov;32(6):394-9. PMID: 18043762; PMCID: PMC2077351.
                        </Citation>
                        <Citation>
                            12. Ma A, Zhuang X, Wu J, Cui M, Lv D, Liu C, Zhuang G. PLOS One. 2013 Jun 20;8(6):e66146. doi: 10.1371/journal.pone.0066146. PMID: 23840414; PMCID: PMC3688710.
                        </Citation>
                        <Citation>
                            13. Hernández-Elvira M, Martínez-Gómez R, Domínguez-Martin E, Méndez A, Kawasaki L, Ongay-Larios L, Coria R. Cells. 2019 Jul 12;8(7):710. doi: 10.3390/cells8070710. PMID:
                            31336877; PMCID: PMC6678945.
                        </Citation>
                        <Citation>
                            14. Calabrese C, Gregory WL, Leo M, Kraemer D, Bone K, Oken B. J Altern Complement Med. 2008 Jul;14(6):707-13. doi: 10.1089/acm.2008.0018. PMID: 18611150; PMCID:
                            PMC3153866.
                        </Citation>
                        <Citation>
                            15. Aguiar S, Borowski T. Rejuvenation Res. 2013 Aug;16(4):313-26. doi: 10.1089/rej.2013.1431. PMID: 23772955; PMCID: PMC3746283.
                        </Citation>
                        <Citation>
                            16. Jamshidi N, Cohen MM. Evid Based Complement Alternat Med. 2017;2017:9217567. doi: 10.1155/2017/9217567. Epub 2017 Mar 16. PMID: 28400848; PMCID: PMC5376420.
                        </Citation>
                        <Citation>
                            17. Montero-Marin J, Puebla-Guedea M, Herrera-Mercadal P, Cebolla A, Soler J, Demarzo M, Vazquez C, Rodríguez-Bornaetxea F, García-Campayo J. Front Psychol. 2016 Dec
                            12;7:1935.
                            doi: 10.3389/fpsyg.2016.01935. Erratum in: Front Psychol. 2020 May 05;11:852. PMID: 28018270; PMCID: PMC5149565.
                        </Citation>
                        <Citation>
                            18. Kimura K, Ozeki M, Juneja LR, Ohira H. Biol Psychol. 2007 Jan;74(1):39-45. doi: 10.1016/j.biopsycho.2006.06.006. Epub 2006 Aug 22. PMID: 16930802.
                        </Citation>
                        <Citation>
                            19. Risen, T. (n.d.). Americans pay an average $2.70 for coffee, while tipping 20 percent. US News.
                        </Citation>
                    </Box>
                </TwelveColumn>
            </Box>
        </>
    );
};
/* eslint-enable max-len, react/jsx-closing-tag-location */

export default AlphaBrainPitchPage;
